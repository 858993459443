import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { message } from 'antd'
import { Input, InfiniteScroll, List, DotLoading } from 'antd-mobile'

import { CheckIcon, ComplianceNotDataIcon } from '../../common/icon'
import ccmsService from '../../services/ccmsService'
import { COMPLIANCE_NEWS_TYPE } from '../../common/const'
import wechatShareService from '../../services/wechatShareService'

import store from '../../store'
import { setState } from '../../actions/compliancePolicyActions'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagination: {
                pageSize: 15,
                pageNum: 1,
                total: 0,
            },
            titleSearch: '',
            compliancePolicyList: [],
            hasMore: false,
            detailId: 0
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.closeWindow)
        wechatShareService.hideOptionMenu()
        store.dispatch(updateIsVisibleLanguageSwitch(false))

        let cacheData = store.getState().compliancePolicyData
        this.setState({...cacheData}, () => {
            if (document.getElementById(this.state.detailId)) {
                document.getElementById(this.state.detailId).scrollIntoView({block: 'center'})
            }
        })
        if (this.props.location.state?.isShowDetailFail) {
            this.onClickCompliancePolicyFilterIcon()
            message.warn(COMPLIANCE_NEWS_TYPE.ENUM.COMPLIANCE_POLICY.label + intl.get('NOT_EXIST'))
        }
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.closeWindow)
        store.dispatch(setState(this.state))
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }
    
    onChangeCompliancePolicyTitleFilter = value => {
        this.setState({titleSearch: value.trim()})
    }


    onClearCompliancePolicyTitleFilter = () => {
        this.setState({
            pagination: {
                pageSize: 15,
                pageNum: 1,
                total: 0,
            },
            compliancePolicyList: []
        }, () => {
            this.getCompliancePolicyList()
        })
    }

    onClickCompliancePolicyFilterIcon = () => {
        this.setState({
            pagination: {
                pageSize: 15,
                pageNum: 1,
                total: 0,
            },
            compliancePolicyList: [],
            hasMore: true
        })
    }

    getCompliancePolicyList = async () => {
        let request = {
            pageSize: this.state.pagination.pageSize,
            pageNumber: this.state.pagination.pageNum,
            complianceType: COMPLIANCE_NEWS_TYPE.ENUM.COMPLIANCE_POLICY.value
        }
        if (this.state.titleSearch) {
            request.title = this.state.titleSearch
        }

        let response = await ccmsService.getComplianceNewsList(request)
        if (response.code === 200) {
            let pagination = {}
            pagination.pageNum = response.data.pageNum < response.data.totalPage ? ++response.data.pageNum : response.data.pageNum
            pagination.pageSize = response.data.pageSize
            pagination.total = response.data.total
            let compliancePolicyList = this.state.compliancePolicyList.concat(response.data.list)
            this.setState({
                compliancePolicyList,
                pagination
            })
        }
    }

    loadMore = async () => {
        await this.getCompliancePolicyList().then(() => {
            if (this.state.pagination.total <= this.state.compliancePolicyList.length) {
                this.setState({hasMore: false})
            } else {
                this.setState({hasMore: true})
            }
        })
    }

    onClickCompliancePolicyDetail = (id) => {
        this.setState({detailId: id}, () => {
            this.props.history.push({ pathname: `/compliance-policy/${id}` })
        })
    }

    closeWindow = () => {
        if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
            window.WeixinJSBridge.call('closeWindow');
        }
    }

    render() {
        document.title = intl.get('COMPLIANCE_POLICY_LIST_TITLE')

        const InfiniteScrollContent = (props) => {
            let hasMore = props.hasMore
            return (
                this.state.compliancePolicyList.length > 0 &&
                <InfiniteScrollContentContainer>
                    {hasMore ? (
                        <>
                            <span>{intl.get('LOADING')}</span>
                            <DotLoading />
                        </>
                    ) : (
                        <span>{intl.get('NO_MORE_DATA')}</span>
                    )}
                </InfiniteScrollContentContainer>
            )
        }

        return (
            <CompliancePolicyContainer>
                <CompliancePolicyFilter>
                    <Input placeholder={intl.get('PLEASE_ENTER_WANT_QUERY_POLICY_NAME')}
                        value={this.state.titleSearch}
                        clearable={true}
                        onClear={this.onClearCompliancePolicyTitleFilter}
                        onEnterPress={this.onClickCompliancePolicyFilterIcon}
                        onChange={this.onChangeCompliancePolicyTitleFilter} />
                    <CheckIcon className='search-btn' onClick={this.onClickCompliancePolicyFilterIcon}/>
                </CompliancePolicyFilter>
                <CompliancePolicyList>
                    {
                        this.state.compliancePolicyList.length > 0 ? 
                        <List>
                            {this.state.compliancePolicyList.map((item, index) => (
                                <List.Item key={index} clickable={false} arrow={true} onClick={this.onClickCompliancePolicyDetail.bind(this, item.id)}>
                                    <CompliancePolicyListItem id={item.id}>
                                        <span>{item.title}</span>
                                    </CompliancePolicyListItem>
                                </List.Item>
                            ))}
                        </List>
                        :
                        <ListNoDataStatusContainer>
                            <ComplianceNotDataIcon/>
                            <div className='message'>{intl.get('COMPLIANCE_POLICY_NOT_FOUND')}</div>
                        </ListNoDataStatusContainer>
                    }
                    <InfiniteScroll loadMore={this.loadMore} hasMore={this.state.hasMore}>
                        <InfiniteScrollContent hasMore={this.state.hasMore} />
                    </InfiniteScroll>
                </CompliancePolicyList>
            </CompliancePolicyContainer>
        )
    }
}

export default withRouter(Index)

const CompliancePolicyContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

const CompliancePolicyFilter = styled.div`
    display: flex;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #E6EFF8;
    height: 44px;
    margin: 13px 16px 0;
    align-items: center;
    .adm-input {
        margin-left: 12px;
        background-color: white;
    }
    .search-btn {
        margin-right: 19px;
    }
`

const CompliancePolicyList = styled.div`
    overflow-x: scroll;
    overflow-y: auto;
    height: calc(100vh - 130px);
    margin-top: 13px;
    -webkit-overflow-scrolling: touch;
    .adm-list {
        margin: 0 17px;
        --border-top: 0;
        --border-bottom: 0;
    }
    .adm-list-body {
        border-radius: 8px;
    }
`

const CompliancePolicyListItem = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`

const InfiniteScrollContentContainer = styled.div`
`

const ListNoDataStatusContainer = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 10vh;
    .message {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.5;
    }
`
