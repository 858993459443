import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'

import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'

import PDFView from '../../components/PDFView'
import ccmsService from '../../services/ccmsService'
import azureService from '../../services/azureService'
import { ICW_CONTENT_TYPE, ICW_TYPES } from '../../common/const'
import wechatShareService from '../../services/wechatShareService'

class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            isPreview: false,  // 是否为预览请求
            icwDetail: {},
            pdfContentUrl: '',
            isPdf: false,
        }
    }

    componentDidMount() {
        store.dispatch(updateIsVisibleLanguageSwitch(false))
        wechatShareService.hideOptionMenu()
        const { id } = this.props.match.params;
        const searchParams = new URLSearchParams(window.location.search)
        const isPreview = searchParams.get('isPreview')
        this.setState({id, isPreview: isPreview === 'true' || false}, () => {
            this.getIcwDetail()
        })
    }

    componentWillUnmount() {
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }

    getIcwDetail = async () => {
        let response = null
        if (this.state.isPreview) {
            response = await ccmsService.getPreviewIcwDetail(this.state.id)
        } else {
            response = await ccmsService.getIcwDetail(this.state.id)
        }
        if (response.code === 200) {
            for(let key in ICW_TYPES.ENUM) {
                if (ICW_TYPES.ENUM[key].value === response.data.infoType) {
                    response.data.title = ICW_TYPES.ENUM[key].label
                }
            }

            let isPdf = response.data.contentType === ICW_CONTENT_TYPE.ENUM.PDF.value
            let pdfContentUrl= ''
            if (isPdf) {
                pdfContentUrl = await azureService.getFileUrlFromAzure(JSON.parse(response.data.contentFile)[0].fullName)
            }

            this.setState({
                icwDetail: response.data,
                pdfContentUrl,
                isPdf,
            }, () => {
                const imgs = document.getElementsByTagName("img");
                for (const img of imgs) {
                    img.addEventListener("click", (e) => {
                        window.wx.previewImage({
                            urls: [e.target.src],
                            current: e.target.src,
                        })
                    });
                }

                if(!isPdf) {
                    const alinks = document.getElementsByTagName("a");
                    setTimeout(() => {
                        for (const alink of alinks) {
                            const link = alink.href
                            const currentLink = window.location.href;
                            if(link && link !== 'javascript:void(0)' && link.indexOf('ccms-test.mcdonalds.com.cn') !==-1 && link.indexOf('ccms.mcdonalds.com.cn') && currentLink.indexOf('code=') === -1) {
                                alink.setAttribute('href', "javascript:void(0)");
                                alink.setAttribute('onclick', "window.location.href ='" + link+'?v='+Math.random()+"'; setTimeout(() => { window.location.reload(); }, 100);");
                            }
                        }
                    }, 200);
                }
            })
        } else {
            if (response.code === 403) {
                this.props.history.replace({
                    pathname: '/error',
                })
            } else {
                this.props.history.replace({
                    pathname: `/integrity-culture-week`
                })
            }

        }
    }

    getDocumentTitle = () => {
        const isProfile = this.state.icwDetail?.infoType === ICW_TYPES.ENUM.PROFILE.value;
        const year = this.state.icwDetail?.year || ''
        const title = isProfile ? intl.get('INTEGRITY_CULTURE_WEEK_PROFILE_TITLE')
            : (year + intl.get('INTEGRITY_CULTURE_WEEK_TITLE'))
        return title
    }

    onClickBackButton = () => {
        this.props.history.goBack()
    }

    render() {
        document.title = this.getDocumentTitle();

        return (
            <DetailContainer>
                <BackButton onClick={this.onClickBackButton}/>
                <DetailContentContainer>
                    <DetailHeaderContainer>
                        <div className="title">
                            {this.state.icwDetail.title}
                        </div>
                    </DetailHeaderContainer>
                    {
                        this.state.isPdf ?
                        <DetailPDFContentContainer>
                            <PDFView pdfUrl={this.state.pdfContentUrl} id={this.state.id}/>
                        </DetailPDFContentContainer>
                        :
                        <DetailImageContentContainer dangerouslySetInnerHTML={{__html:this.state.icwDetail.content}}/>
                    }
                </DetailContentContainer>
            </DetailContainer>
        )
    }
}

export default withRouter(Detail)

const BackButton = styled.div`
    position: absolute;
    top: 25px;
    left: 16px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #eadede;
    border-right: 2px solid #eadede;
    transform: rotate(-135deg);
`

const DetailContainer = styled.div`
    display: flex;
`

const DetailContentContainer = styled.div`
    background-color: white;
    width: 100%;
    margin: -10px 16px 16px;
    border-radius: 8px;
    overflow-x: hidden;
`

const DetailHeaderContainer = styled.div`
    padding: 20px 20px 8px;
    .title {
        font-size: 18px;
        font-weight: bold;
    }
`

const DetailPDFContentContainer = styled.div`
    padding: 0px 16px;
`

const DetailImageContentContainer = styled.div`
    padding: 0 20px 20px;
    word-break: break-all;
    ul {
        list-style-type: disc;
        margin-left: -6px;
        ul {
            list-style-type: circle;
            ul {
                list-style-type: square;
            }
        }
    }
    li {
        list-style-position: outside;
    }
    ol {
        margin-left: -6px;
        list-style: decimal;
    }
    video {
        max-width: 100%;
    }
    img {
        max-width: 100% !important;
        height: auto !important;
    }
`
