import { combineReducers } from 'redux'
import languageReducer from './languageReducer'
import commonReducer from './commonReducer'
import complianceTipsReducer from './complianceTipsReducer'
import compliancePolicyReducer from './compliancePolicyReducer'

const allReducers = {
	LanguageData: languageReducer,
	commonData: commonReducer,
	complianceTipsData: complianceTipsReducer,
	compliancePolicyData: compliancePolicyReducer,
}

const rootReducer = combineReducers(allReducers)
export default rootReducer
