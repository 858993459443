import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Image, Button } from 'antd'

import successIcon from '../assets/images/success_icon.svg'

const PcSuccessContainer = styled.div`
    width: 1024px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    min-height: calc(100vh - 140px);

    .ant-btn {
        width: 190px;
        height: 44px;
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0 20px;
`

const HeaderRightContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: 20px;
`

const SubmitSuccessLabel = styled.div`
    font-size: 20px;
    font-weight: bold;
`

const ContentContainer = styled.div`
    border-radius: 10px;
    padding: 20px 16px 40px;
`

const InformNumberContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const InformNumberLabel = styled.p`
    font-size: 20px;
    font-weight: bold;
`

const InformNumber = styled.p`
    font-size: 28px;
    font-weight: bold;
    margin-left: 10px;
`

const InformNumberMessageLabel = styled.p`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
`

const InformNumberMessageSubLabel = styled.p`
    font-weight: bold;
    opacity: 0.8;
    text-align: center;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0
`

const WidthBox = styled.div`
    width: 44px;
    height: 100%;
`

class PcSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onClickGoHome = () => {
        sessionStorage.setItem('previousPage', 'success')
        this.props.history.push({ pathname: '/home' })
    }

    onClickNewInform = () => {
        sessionStorage.setItem('previousPage', 'success')
        this.props.history.push({ pathname: '/form' })
    }

    render() {
        return (
            <PcSuccessContainer className="pc-success-container">
                <HeaderContainer className="success-header-container">
                    <Image className="success-icon" preview={false} height={90} src={successIcon} />
                    <HeaderRightContainer>
                        <SubmitSuccessLabel>{intl.get('SUBMIT_SUCCESS')}</SubmitSuccessLabel>
                        <InformNumberContainer>
                            <InformNumberLabel>{intl.get('INFORM_NUMBER_LABEL')}</InformNumberLabel>
                            <InformNumber>{this.props.match.params.code.toUpperCase()}</InformNumber>
                        </InformNumberContainer>
                    </HeaderRightContainer>
                </HeaderContainer>
                <ContentContainer>
                    <InformNumberMessageLabel>{intl.get('INFORM_NUMBER_MESSAGE_LABEL1')}</InformNumberMessageLabel>
                    <InformNumberMessageSubLabel>{intl.get('INFORM_NUMBER_MESSAGE_LABEL2')}</InformNumberMessageSubLabel>
                    <InformNumberMessageSubLabel>{intl.get('INFORM_NUMBER_MESSAGE_LABEL3')}</InformNumberMessageSubLabel>
                </ContentContainer>

                <ButtonContainer>
                    <Button block type="primary" shape="round" onClick={this.onClickGoHome}>{intl.get('BUTTON_GO_HOME')}</Button>
                    <WidthBox />
                    <Button block type="primary" shape="round" onClick={this.onClickNewInform}>{intl.get('BUTTON_NEW_INFORM')}</Button>
                </ButtonContainer>
            </PcSuccessContainer>
        )
    }
}

export default withRouter(PcSuccess)
