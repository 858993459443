export const FILE_CHECK_RESULT_TYPE_ERROR = 'FILE_CHECK_RESULT_TYPE_ERROR'
export const FILE_CHECK_RESULT_SIZE_ERROR = 'FILE_CHECK_RESULT_SIZE_ERROR'
export const FILE_CHECK_RESULT_VALID = 'FILE_CHECK_RESULT_VALID'

export const SUPPORTED_FILES_TYPE_FOR_DOC = ['txt', 'doc', 'docx', 'dot', 'dotx', 'xls', 'xlt', 'xlsx', 'ppt', 'pptx', 'pdf', 'csv']
export const SUPPORTED_FILES_TYPE_FOR_IMAGE = ['jpe', 'jpeg', 'jpg', 'png', 'gif', 'dwg']
export const SUPPORTED_FILES_TYPE_FOR_VIDEO = ['mp4', 'mov', 'avi', '3gp', 'rmvb', 'flv']
export const SUPPORTED_FILES_TYPE_FOR_AUDIO = ['mp3', 'm4a', 'wav']
export const SUPPORTED_FILES_TYPE_FOR_OTHER = ['zip', 'rar', '7z']


export const INFORMER_SECURITY_TYPE_ENUM = {
    REAL_NAME: {
        value: 'real_name',
        label: '实名'
    },
    ANONYMOUS: {
        value: 'anonymous',
        label: '匿名'
    }
}

export const INFORMER_SECURITY_TYPE_OPTIONS = [
    {...INFORMER_SECURITY_TYPE_ENUM.REAL_NAME},
    {...INFORMER_SECURITY_TYPE_ENUM.ANONYMOUS}
]

// 合规信息类型
export const COMPLIANCE_NEWS_TYPE = {
    ENUM: {
        COMPLIANCE_POLICY: {
            value: 1,
            label: '合规政策'
        },
        COMPLIANCE_TIPS: {
            value: 2,
            label: '合规提示'
        }
    },
    OPTIONS: [
        { value: 1, label: '合规政策'},
        { value: 2, label: '合规提示'},
    ]
}

// 合规信息内容类型
export const COMPLIANCE_NEWS_CONTENT_TYPE = {
    ENUM: {
        IMAGE_TEXT: {
            value: 1,
            label: '图文内容'
        },
        PDF: {
            value: 2,
            label: 'PDF内容'
        }
    },
    OPTIONS: [
        { value: 1, label: '图文内容'},
        { value: 2, label: 'PDF文件'},
    ]
}

export const RESTRICTIVE_PARTNER_LIST_OPTIONS = {
    RESTRICTIVE_PARTNER_TYPE: {
        ENUM: {
            COMPANY: {
                value: 1,
                label: '公司'
            },
            PERSONAL: {
                value: 2,
                label: '个人'
            }
        },
    },
    RESTRICTIVE_TYPE: {
        ENUM: {
            TYPE_A: {
                value: 1,
                label: 'A'
            },
            TYPE_B: {
                value: 2,
                label: 'B'
            },
            TYPE_C: {
                value: 3,
                label: 'C'
            },
            TYPE_D: {
                value: 4,
                label: '其他'
            }
        },
    },
}

// 诚信文化周类型
export const ICW_TYPES = {
    ENUM: {
        PROFILE: {
            value: 1,
            label: '简介',
        },
        TITLE: {
            value: 2,
            label: '标题',
        },
        NEWS: {
            value: 3,
            label: '新闻',
        },
        PARTICIPATION: {
            value: 4,
            label: '参与情况',
        },
        AWARDS_LIST: {
            value: 5,
            label: '获奖名单',
        }
    }
};

// 诚信文化周内容类型
export const ICW_CONTENT_TYPE = {
    ENUM: {
        IMAGE_TEXT: {
            value: 1,
            label: '图文内容'
        },
        PDF: {
            value: 2,
            label: 'PDF内容'
        },
        TEXT: {
            value: 3,
            label: '文本'
        }
    },
}
