import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { message, Spin } from 'antd'
import ReactDOM from 'react-dom'
import ccmsService from '../../services/ccmsService'
import wechatShareService from '../../services/wechatShareService'
import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'
import Config from '../../config/initEnv'
import { checkDeviceIsMobile } from '../../common/utils'
import h5GameHeaderBg from '../../assets/images/h5_game_header_bg.png'
import h5GameHeaderLogo from '../../assets/images/h5_game_header_logo.png'
import h5GameHeaderTitle from '../../assets/images/h5_game_header_title.png'
import h5GameBottomBg from '../../assets/images/h5_game_bottom_bg.png'
import h5GameMarketSelector from '../../assets/images/h5_game_market_selector.png'
import h5GameEnterGameDisabledButton from '../../assets/images/h5_game_enter_game_disabled_button.png'
import h5GameEnterGameButton from '../../assets/images/h5_game_enter_game_button.png'

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            marketOptions: ['南研', 'BSC', 'MCCL', '江苏', '上海', '浙江', '北京', '湖北陕西', '东北', '四川', '天津山东', '福建', '广州', '湖南', '深圳'],
            selectedMarket: '',
            supplyMarketToken: '',
            isShowPage: false,
        }
    }

    componentDidMount() {
        this.showLoading()
        wechatShareService.hideOptionMenu()
        store.dispatch(updateIsVisibleLanguageSwitch(false))

        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');

        if (!code) {
            this.getWechatWorkOAuth2Code();
        } else {
           this.authH5Game(code);
        }
    }

    componentWillUnmount() {
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }

    async authH5Game(code) {
        const res = await ccmsService.authH5Game(code)
        const goPage = checkDeviceIsMobile() ? this.props.history.push : this.props.history.replace
        if (res.code !== 200) {
            this.hideLoading();
            goPage({ pathname: `/error`, state: { code: res.code } })
        } else {
            const data = res.data;
            if (data.indexOf('http') !== -1 || data.indexOf('https') !== -1) {
                window.location.replace(data);
            } else {
                this.hideLoading();
                this.setState({ supplyMarketToken: data, isShowPage: true })
            }
        }
    }

    getWechatWorkOAuth2Code() {
        const redirectUri = encodeURIComponent(window.location.href)
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.wxWorkCorpId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
        window.location.replace(url)
    }

    showLoading() {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.render(<Spin size="large" />, dom)
    }

    hideLoading() {
        document.body.removeChild(document.getElementById('loading'))
    }

    onClickMarket = (market) => {
        this.setState({ selectedMarket: market === this.state.selectedMarket ? '' : market })
    };

    onClickEnterGame = async () => {
        this.showLoading()
        let res = await ccmsService.supplyH5GameMarket(this.state.supplyMarketToken, this.state.selectedMarket)
        if (res.code !== 200) {
            this.hideLoading()
            message.error('页面已过期，请重新扫码或点击链接');
        } else {
            window.location.replace(res.data);
        }
    }

    onClickDisabledEnterGame = () => {
        message.warning('请选择市场/区域');
    }

    render() {
        document.title = intl.get('INTEGRITY_CULTURE_WEEK_TITLE')

        return (
            <>
            {
            this.state.isShowPage &&
            <Container>
                <Header>
                    <HeaderLogo />
                    <HeaderTitle />
                    <HeaderTips>选择市场/区域，即可进入游戏</HeaderTips>
                </Header>
                <MarketSelector>
                    <MarketList>
                        {
                            this.state.marketOptions.map((market) => (
                                <MarketItem
                                    key={market}
                                    className={this.state.selectedMarket === market ? 'selected-market-item' : ''}
                                    onClick={() => this.onClickMarket(market)}>
                                        {market}
                                </MarketItem>
                            ))
                        }
                    </MarketList>
                    <MarketSelectorBg />
                </MarketSelector>
                <Bottom>
                    {
                        !this.state.selectedMarket
                        ? <EnterGameDisabledButton onClick={this.onClickDisabledEnterGame} />
                        : <EnterGameButton onClick={this.onClickEnterGame} />
                    }
                    <BottomBg />
                </Bottom>
            </Container>
            }
            </>
        )
    }
}

export default withRouter(Index)

const calcAdaptivePx = (px) => {
    return `${px * parseFloat(window.innerHeight / 768)}px}`
}

const Container = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin: 0 auto;
`

const Header = styled.div`
    height: ${calcAdaptivePx(218)};
    background-image: url(${h5GameHeaderBg});
    background-size: cover;
    background-position: 0 ${calcAdaptivePx(-30)};
`

const HeaderLogo = styled.div`
    width: ${calcAdaptivePx(63)};
    height: ${calcAdaptivePx(51)};
    margin-left: ${calcAdaptivePx(11)};
    background-image: url(${h5GameHeaderLogo});
    background-size: cover;
    background-position: center;
`

const HeaderTitle = styled.div`
    width: ${calcAdaptivePx(145)};
    height: ${calcAdaptivePx(39)};
    margin-top: ${calcAdaptivePx(13)};
    margin-left: ${calcAdaptivePx(16)};
    background-image: url(${h5GameHeaderTitle});
    background-size: cover;
    background-position: center;
`

const HeaderTips = styled.div`
    height: ${calcAdaptivePx(20)};
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: ${calcAdaptivePx(14)};
    color: #333333;
    line-height: ${calcAdaptivePx(20)};
    text-align: left;
    font-style: normal;
    margin-top: ${calcAdaptivePx(10)};
    margin-left: ${calcAdaptivePx(17)};
`

const MarketSelector = styled.div`
    position: relative;
    height: ${calcAdaptivePx(363)};
`

const MarketSelectorBg = styled.div`
    position: absolute;
    top: ${calcAdaptivePx(-130)};
    transform: translate(-50%);
    left: 50%;
    width: ${calcAdaptivePx(350)};
    height: ${calcAdaptivePx(494)};
    background-image: url(${h5GameMarketSelector});
    background-size: cover;
    background-position: center;
`

const MarketList = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    width: ${calcAdaptivePx(375)};
    transform: translate(-50%);
    left: 50%;
    padding: 0 ${calcAdaptivePx(43.5)};
    .selected-market-item {
        color: #fff;
        background: #F90016;
    }
`

const MarketItem = styled.div`
    width: ${calcAdaptivePx(76)};
    height: ${calcAdaptivePx(38)};
    line-height: ${calcAdaptivePx(38)};
    background: #FFBD00;
    border-radius: ${calcAdaptivePx(19)};
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: ${calcAdaptivePx(14)};
    color: #573937;
    text-align: center;
    font-style: normal;
    margin-top: ${calcAdaptivePx(12)};
    margin-bottom: ${calcAdaptivePx(12)};
    margin-left: ${calcAdaptivePx(10)};
    margin-right: ${calcAdaptivePx(10)};
    cursor: pointer;
`

const Bottom = styled.div`
    height: ${calcAdaptivePx(187)};
    position: relative;
`

const BottomBg = styled.div`
    height: ${calcAdaptivePx(217)};
    background-image: url(${h5GameBottomBg});
    background-size: cover;
    background-position: center;
    position: relative;
    top: ${calcAdaptivePx(-30)};
`

const EnterGameDisabledButton = styled.div`
    background-image: url(${h5GameEnterGameDisabledButton});
    background-size: cover;
    background-position: center;
    width: ${calcAdaptivePx(180)}
    height: ${calcAdaptivePx(47)}
    position: absolute;
    bottom: ${calcAdaptivePx(58)}
    z-index: 1;
    transform: translate(-50%);
    left: 50%;
    cursor: pointer;
`

const EnterGameButton = styled.div`
    background-image: url(${h5GameEnterGameButton});
    background-size: cover;
    background-position: center;
    width: ${calcAdaptivePx(180)};
    height: ${calcAdaptivePx(47)};
    position: absolute;
    bottom: ${calcAdaptivePx(58)};
    z-index: 1;
    transform: translate(-50%);
    left: 50%;
    cursor: pointer;
`

