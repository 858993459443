import React, { Component } from 'react'
import styled from 'styled-components'
import { Space } from 'antd'
import { DotLoading } from 'antd-mobile'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import {
    ZoomInOutlined,
    ZoomOutOutlined,
  } from '@ant-design/icons';
import intl from 'react-intl-universal'
import { checkDeviceIsMobile } from '../common/utils'
import store from '../store'
import { updatePcContainerWidth } from '../actions/commonActions'

const PdfView = styled.div`
    width: 100%;
    margin: -10px auto 10px;
    overflow-x: auto;
    overflow-y: hidden;
    .page-header {
        border-bottom: 6px solid #f2f5f8;
    }
    .pdf-load {
        margin-top: 5px;
        margin-left: 18px;
    }
    .pdf-tool{
        position: fixed;
        line-height: 20px;
        width: 100%;
        z-index: 10;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
        .space {
            color: white;
            border-radius: 15px;
            padding: 8px 15px;
            background: rgb(66, 66, 66);
        }
    }
`

export default class PDFView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pdfUrl: props.pdfUrl,
            id: props.id,
            numPages: null,
            pageNumber: 1,
            renderedPageNumber: 1, // 已渲染的页数
            isMobile: false,
            baseWidth: document.documentElement.clientWidth,
            pageWidth: null,
            isShowPdfTool: false,
        }
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleOnScroll)
        const isMobile = checkDeviceIsMobile()
        if (isMobile) {
            window.addEventListener("orientationchange", this.handleOnOrientationChange)
        }
        const baseWidth = isMobile ? document.documentElement.clientWidth : 740
        this.setState({ 
            isMobile,
            baseWidth,
            pageWidth: baseWidth - 64,
         })
        store.dispatch(updatePcContainerWidth(740))
    }

    componentWillUnmount() {
        store.dispatch(updatePcContainerWidth(650))
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        // 默认先渲染10页，总页数未超过10页的全部渲染
        const renderedPageNumber = numPages > 10 ? 10: numPages
        this.setState({ numPages, renderedPageNumber, isShowPdfTool: true })
    }

    onRenderSuccess = ({ page }) => {
        const alinks = document.getElementsByTagName("a");
        setTimeout(() => {
            for (const alink of alinks) {
                const link = alink.href
                const currentLink = window.location.href;
                if(link && link !== 'javascript:void(0)' && link.indexOf('ccms-test.mcdonalds.com.cn') !==-1 && link.indexOf('ccms.mcdonalds.com.cn') !==-1 &&currentLink.indexOf('code=') === -1) {
                    alink.setAttribute('href', "javascript:void(0)");
                    // setTimeout(() => { window.location.reload(); }, 100);
                    alink.setAttribute('onclick', "window.location.href ='" + link+'?v='+Math.random()+"'; setTimeout(() => { window.location.reload(); }, 100);");
                }          
            } 
        }, 100); 
    }

    pageZoomOut = () => {
        let pageWidth = this.state.pageWidth * 0.8
        if (pageWidth <= (this.state.baseWidth - 64)) {
            pageWidth = this.state.baseWidth - 64
        }
        store.dispatch(updatePcContainerWidth(pageWidth + 64))
        this.setState({ pageWidth: pageWidth })
    }
    
    pageZoomIn = () => {
        const pageWidth = this.state.pageWidth * 1.2
        if (pageWidth > (this.state.isMobile ? 800 : document.documentElement.clientWidth - 64)) {
            return
        }
        store.dispatch(updatePcContainerWidth(pageWidth + 64))
        this.setState({ pageWidth: pageWidth })
    }

    handleOnScroll = () => {
        let { renderedPageNumber, numPages } = this.state

        let pdfPageHeight = Math.round(document.getElementById("pdfiew")?.offsetHeight / renderedPageNumber)
        let scrollTop = (document.documentElement.scrollTop || document.body.scrollTop) + Math.round(pdfPageHeight / 2);
        let pageNumber = Math.floor(scrollTop / pdfPageHeight) + 1
        pageNumber = pageNumber < numPages ? pageNumber : numPages

        // 快要滑动到已渲染的最后一页时（当前页和已渲染的最后一页之间少于或等于5页），接着再渲染5页
        if (renderedPageNumber < numPages && pageNumber >= renderedPageNumber - 5 && pageNumber <= renderedPageNumber) {
            const val = renderedPageNumber + 5
            renderedPageNumber = val > numPages ? numPages : val
        }
        // 某些情况下，当前页计算不准确会导致当前页>已渲染的最后一页, 造成无法滑动，这时候需要修正renderedPageNumber
        if (pageNumber > renderedPageNumber) {
            const val = (pageNumber / 5 + 1) * 5 + 5
            renderedPageNumber = val > numPages ? numPages : val
        }

        this.setState({pageNumber, renderedPageNumber})

    }

    handleOnOrientationChange = () => {
        const baseWidth = document.documentElement.clientWidth
        this.setState({ 
            baseWidth,
            pageWidth: baseWidth - 64,
         })
    }

    render() {
        pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/static/pdf.worker.min.js'
        const { pageNumber, numPages, pageWidth, isShowPdfTool, id } = this.state
        const customClassName = "doc" + id;
        return (
            <PdfView id='pdfiew' onScrollCapture={this.handleOnScroll}>
                <Document
                    className= {customClassName}
                    file={this.state.pdfUrl}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    page = {pageNumber}
                    externalLinkTarget="_top"
                    loading={<DotLoading className="pdf-load"/>}
                    noData={<div className='pdf-load'>{intl.get('PDF_NO_DATA')}</div>}
                    error={<div className='pdf-load'>{intl.get('PDF_LOAD_ERROR')}</div>}>
                    {new Array(this.state.renderedPageNumber).fill('').map((cur, index) => (
                        <>
                            <Page
                                key={index}
                                width={pageWidth}
                                scale={1}
                                pageNumber={index + 1}
                                onRenderSuccess={this.onRenderSuccess}
                                loading=""
                                renderAnnotationLayer={true}/>
                            {
                                (index + 1) !== this.state.numPages &&
                                <div className='page-header' style={{ width: pageWidth }}></div>
                            }
                        </>
                    ))}
                </Document>
                {
                    isShowPdfTool && 
                    <div className="pdf-tool">
                        <Space size={20} className="space" style={{ fontSize: 15 }}>
                            <span className='page-num'>{pageNumber} / {numPages}</span>
                            <span onClick={this.pageZoomIn} >
                                <ZoomInOutlined/>
                            </span>
                            <span type="minus" onClick={this.pageZoomOut} >
                                 <ZoomOutOutlined/>
                            </span>
                        </Space>
                    </div>
                }
            </PdfView>
        )
    }
}
