import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Modal, Button } from 'antd'

import store from '../store'
import { QuestionMarkIcon, MobileHomeHeaderImage } from '../common/icon'
import {
    updateIsCheckedCurrentContent,
    updateIsCheckedPrivacyPolicy,
    updateHasReadPrivacyPolicy
} from '../actions/commonActions'

const MobileHomeContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PlatformSectionContainer = styled.div`
    width: 100vw;
    padding: 0 16px;
    margin-top: -8px;
    z-index: 10;
`

const PlatformContentContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 30px 20px 30px 20px;
`

const PlatformTitle = styled.div`
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`

const QuestionContainer = styled.div`
    display: flex;
    margin-top: 30px;
`

const QuestionLabel = styled.p`
    color: #1890ff;
    font-weight: bold;
    margin-left: 10px;
`

const QuestionContentContainer = styled.div`
    margin: 6px 0;
`

const FrequentlyQuestionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
`

const FrequentlyQuestionContent = styled.div`
    overflow: auto;
    height: calc(100vh - 200px);
    margin-top: 10px;
    padding-right: 6px;
`

const NextStepButtonContainer = styled.div`
    margin: 50px 0 30px;
`

class MobileHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isQuestionContentModalVisible: false,
            questionContentContainerHeight: 0
        }
    }

    componentDidMount() {
        document.body.scrollIntoView()
        store.dispatch(updateIsCheckedCurrentContent(false))
        store.dispatch(updateIsCheckedPrivacyPolicy(false))
        store.dispatch(updateHasReadPrivacyPolicy(false))
        this.setState({
            questionContentContainerHeight: document.body.clientHeight - 200
        })
    }

    OnClickQuestionContainer = () => {
        this.setState({
            isQuestionContentModalVisible: true
        })
    }

    closeQuestionContentModal = () => {
        this.setState({
            isQuestionContentModalVisible: false
        })
    }

    onClickNextStep = () => {
        sessionStorage.setItem('previousPage', 'home')
        this.props.history.push({ pathname: '/notice' })
    }

    render() {
        return (
            <MobileHomeContainer className="mobile-home-container">
                <MobileHomeHeaderImage />
                <PlatformSectionContainer>
                    <PlatformContentContainer>
                        <PlatformTitle>{intl.get('PLATFORM_TITLE')}</PlatformTitle>
                        <ContentContainer>
                            <div>
                                {
                                    Array(2).fill().map((_, i) => (
                                        <div key={i} dangerouslySetInnerHTML={{ __html: intl.get('PLATFORM_CONTENT_LABEL' + (i + 1)) }} />
                                    ))
                                }
                            </div>
                        </ContentContainer>
                        <QuestionContainer onClick={this.OnClickQuestionContainer}>
                            <QuestionMarkIcon />
                            <QuestionLabel>{intl.get('FREQUENTLY_QUESTION')}</QuestionLabel>
                        </QuestionContainer>
                        <Modal
                            maskClosable={false}
                            centered={true}
                            visible={this.state.isQuestionContentModalVisible}
                            footer={null}
                            onCancel={this.closeQuestionContentModal}
                            width={"90vw"}>
                            <QuestionContentContainer>
                                <FrequentlyQuestionTitle>{intl.get('FREQUENTLY_QUESTION_TITLE')}</FrequentlyQuestionTitle>
                                <FrequentlyQuestionContent>
                                    {
                                        Array(12).fill().map((_, i) => (
                                            <div key={i} dangerouslySetInnerHTML={{ __html: intl.get('FREQUENTLY_QUESTION_LABEL' + (i + 1)) }} />
                                        ))
                                    }
                                </FrequentlyQuestionContent>
                            </QuestionContentContainer>
                        </Modal>
                    </PlatformContentContainer>

                    <NextStepButtonContainer>
                        <Button block type="primary" shape="round" onClick={this.onClickNextStep}>{intl.get('BUTTON_NEXT_STEP')}</Button>
                    </NextStepButtonContainer>
                </PlatformSectionContainer>
            </MobileHomeContainer>
        )
    }
}

export default withRouter(MobileHome)
