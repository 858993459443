import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'

import ccmsService from '../../services/ccmsService'
import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'

import icwListImg from '../../assets/images/icw_list_img1.svg'
import icwListProfileText from '../../assets/images/icw_list_profile_text.png'
import icwListHistoryTitle from '../../assets/images/icw_list_history_title.png'
import icwArrowRightIcon from '../../assets/images/arrow_right_2_icon.svg'
import wechatShareService from '../../services/wechatShareService'

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            integrityCultureWeekList: [],
            profile: {},
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.closeWindow)
        wechatShareService.hideOptionMenu()
        store.dispatch(updateIsVisibleLanguageSwitch(false))
        this.getIcwList();
        this.getProfile();
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.closeWindow)
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }

    closeWindow = () => {
        if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
            window.WeixinJSBridge.call('closeWindow');
        }
    }

    getIcwList = async () => {
        const response = await ccmsService.getIcwList()
        if (response.code === 200) {
            const integrityCultureWeekList = this.state.integrityCultureWeekList.concat(response.data.list)
            this.setState({
                integrityCultureWeekList,
            })
        } else if (response.code === 401 || response.code === 403) {
            this.props.history.replace({
                pathname: '/error',
            })
        }
    }

    getProfile = async() => {
        const response = await ccmsService.getIcwProfile()
        if (response.code === 200) {
            this.setState({
                profile: response.data,
            })
        }
    }

    onClickProfile = () => {
        this.props.history.push({ pathname: `/integrity-culture-week/detail/${this.state.profile.id}` })
    }

    onClickIcwDetail = (id) => {
        this.props.history.push({ pathname: `/integrity-culture-week/${id}` })
    }

    render() {
        document.title = intl.get('INTEGRITY_CULTURE_WEEK_TITLE')

        return (
            <IcwContainer>
                <IcwTitle>
                    <ListTitleImg />
                    <div className="title-content">
                        <ProfileTextImg onClick={ this.onClickProfile }/>
                        <div className="title">带你了解诚信文化周</div>
                    </div>
                </IcwTitle>
                <IcwListContainer>
                    {
                        this.state.integrityCultureWeekList.length > 0 && (
                            <div>
                                <HistoryTitleImg />
                                <IcwList>
                                    {
                                        this.state.integrityCultureWeekList.map((item) => (
                                            <IcwListItem key={item.id} onClick={this.onClickIcwDetail.bind(this, item.id)}>
                                                <div>
                                                    <span className="year">
                                                        <span className="year-text">{item.year}</span>
                                                    </span>
                                                    <span>诚信文化周</span>
                                                </div>
                                                <IcwArrowRightIcon />
                                            </IcwListItem>
                                        ))
                                    }
                                </IcwList>
                            </div>
                        )
                    }
                </IcwListContainer>
            </IcwContainer>
        )
    }
}

export default withRouter(Index)

const IcwContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 60px);
    background: linear-gradient( 180deg, #fcfdff 0%, #e1effe 24vh, #bdddfb 94%, #c2dffa 100%);
    padding: 40px 16px;
`

const IcwTitle = styled.div`
    display: flex;

    .title-content {
        padding-top: 10px;
    }

    .title {
      color: #808589;
      padding-top: 8px;
    }
`

const IcwListContainer = styled.div`
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 36px 20px 20px 20px;
    text-align: center;
    flex: 1;
`

const IcwList = styled.div`
    padding: 28px 0;
`

const IcwListItem = styled.div`
    background: #FFFFFF;
    width: 100%;
    padding: 0 24px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.06);
    border-radius: 8px;
    min-height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 18px;
    color: #3B3B3B;
    line-height: 25px;
    font-weight: 600;

    .year {
        margin-right: 6px;
        position: relative;
    }

    .year::before {
        position: absolute;
        content: '';
        height: 6px;
        background: #FFBD0D;
        border-radius: 3px;
        bottom: 2px;
        left: -2px;
        right: -2px;
        z-index: 0;
    }

    .year-text {
       position: relative;
       z-index: 1;
    }
`

const ListTitleImg = styled.img.attrs({
    src: `${icwListImg}`
})`
    width: 130px;
    margin: 0 30px 0 10px;
`

const ProfileTextImg = styled.img.attrs({
    src: `${icwListProfileText}`
})`
    width: 141px;
`

const HistoryTitleImg = styled.img.attrs({
    src: `${icwListHistoryTitle}`
})`
    width: 218px
`

const IcwArrowRightIcon = styled.img.attrs({
    src: `${icwArrowRightIcon}`
})`
    width: 8px;
    height: 13px;
`
