import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'

import { Error as IconError } from '../common/icon'
import wechatShareService from '../services/wechatShareService'
import store from '../store'
import { updateIsVisibleLanguageSwitch } from '../actions/commonActions'

class Error extends Component {
    componentDidMount() {
        wechatShareService.hideOptionMenu()
        store.dispatch(updateIsVisibleLanguageSwitch(false))
    }

    render() {
        window.addEventListener('popstate', (state) => {
            if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
                window.WeixinJSBridge.call('closeWindow');
            }
        })
        const errorCode = this.props.location.state?.code
        const isNotPermission = !errorCode || (errorCode !== 11000 && errorCode !== 11001);
        return (
            <ErrorContainer>
                <IconError/>
                <div className="message">
                    {
                        isNotPermission && intl.get('INVALID_USER_1')
                    }
                    {
                        errorCode === 11000 && intl.get('AUTH_WECHAT_WORK_LIMIT_RATE')
                    }
                    {
                        errorCode === 11001 && intl.get('AUTH_H5_GAME_LIMIT_RATE')
                    }
                </div>
                {
                    isNotPermission && 
                    <div className="reason">
                        {intl.get('INVALID_USER_2')}
                    </div>
                }
            </ErrorContainer>
        )
    }
}

export default withRouter(Error)

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .message {
        margin-top: 30px;
        font-size: 16px;
        font-weight: bold;
    }
    .reason {
        margin-top: 10px;
        font-size: 14px;
        color: #7D7D7D;
    }
`
