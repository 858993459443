import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'

import ccmsService from '../../services/ccmsService'
import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'

import { ICW_TYPES } from '../../common/const'

import icwSummaryTitleText from '../../assets/images/icw_summary_title_text.png'
import icwNewsImg from '../../assets/images/icw_newsImg.svg'
import icwAwardListImg from '../../assets/images/icw_awardListImg.svg'
import icwParticipantImg from '../../assets/images/icw_participantImg.svg'
import { ArrowRightIcon } from '../../common/icon'
import icwNewsText from '../../assets/images/icw_newsText.png'
import icwParticipantText from '../../assets/images/icw_participant_text.png'
import icwAwardListText from '../../assets/images/icw_awardListText.png'
import wechatShareService from '../../services/wechatShareService'

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            isPreview: false,  // 是否为预览请求
            title: null,
            items: {},
            year: '',
        }
    }

    componentDidMount() {
        store.dispatch(updateIsVisibleLanguageSwitch(false))
        wechatShareService.hideOptionMenu()
        const { id } = this.props.match.params;
        const searchParams = new URLSearchParams(window.location.search)
        const isPreview = searchParams.get('isPreview')

        this.setState({ id, isPreview: isPreview === 'true' || false }, () => {
            this.getIcwSummary(id);
        })
    }

    componentWillUnmount() {
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }

    getIcwSummary = async (id) => {
        const response = this.state.isPreview ? await ccmsService.getPreviewIcwSummary(id) : await ccmsService.getIcwSummary(id);
        if (response.code === 200) {
            const titleItem = response.data?.infoList.find((item) => item.infoType === ICW_TYPES.ENUM.TITLE.value)
            this.setState({
                title: titleItem?.content,
            })

            const items = response.data?.infoList.filter((item) => item.infoType !== ICW_TYPES.ENUM.TITLE.value)
            this.setState({ items, year: response.data?.year || '' })
        } else {
            if (response.code === 403) {
                this.props.history.replace({
                    pathname: '/error',
                })
            } else {
                this.props.history.replace({
                    pathname: `/integrity-culture-week`
                })
            }
        }
    }

    onClickIcwDetail = (id) => {
        this.setState({detailId: id}, () => {
            this.props.history.push({ pathname: `/integrity-culture-week/detail/${id}` })
        })
    }

    onClickBackButton = () => {
        this.props.history.goBack()
    }
    
    render() {
        document.title = this.state.year + intl.get('INTEGRITY_CULTURE_WEEK_TITLE')

        return (
            <IcwSummaryContainer>
                <BackButton onClick={this.onClickBackButton}/>
                <IcwSummaryTitle>
                    <SummaryTitleBgImg />
                    <div className="title">{ this.state.title }</div>
                </IcwSummaryTitle>
                {this.state.items?.length > 0 && <IcwSummaryListContainer>
                    {
                        this.state.items.map((item) => (
                            <IcwSummaryListItem onClick={this.onClickIcwDetail.bind(this, item.id)} key={item.id}>
                                { item.infoType === 3 && <IcwItemInfo>
                                    <div>
                                        <IcwIcwNewsTextImg /><ArrowRightIcon />
                                        <div className="info-des">新闻汇总一目了然</div>
                                    </div>
                                    <IcwNewsImg />
                                </IcwItemInfo> }
                                { item.infoType === 4 && <IcwItemInfo>
                                    <div>
                                        <IcwParticipantTextImg /><ArrowRightIcon />
                                        <div className="info-des">详细了解参与现场</div>
                                    </div>
                                    <IcwParticipantImg />
                                </IcwItemInfo> }
                                { item.infoType === 5 && <IcwItemInfo>
                                    <div>
                                        <IcwAwardListTextImg /><ArrowRightIcon />
                                        <div className="info-des">看看有谁获得荣誉</div>
                                    </div>
                                    <IcwAwardListImg />
                                </IcwItemInfo> }
                            </IcwSummaryListItem>
                        ))
                    }
                </IcwSummaryListContainer>}
            </IcwSummaryContainer>
        )
    }
}

export default withRouter(Index)

const BackButton = styled.div`
    position: absolute;
    top: 25px;
    left: 16px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #eadede;
    border-right: 2px solid #eadede;
    transform: rotate(-135deg);
`

const IcwSummaryContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 60px);
    background: linear-gradient( 180deg, #fcfdff 0%, #e1effe 24vh, #bdddfb 94%, #c2dffa 100%);
`

const IcwSummaryTitle = styled.div`
    position: relative;
    text-align: center;
    padding-top: 66px;

    .title {
      color: #3B3B3B;
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      padding: 0 4px;
    }
`

const SummaryTitleBgImg = styled.img.attrs({
    src: `${icwSummaryTitleText}`
})`
    position: absolute;
    bottom: 14px;
    width: 292px;
    left: 50%;
    transform: translateX(-50%);
`

const IcwSummaryListContainer = styled.div`
    padding: 44px 16px 28px 16px;
`

const IcwSummaryListItem = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 12px 0px rgba(28,30,65,0.06);
    border-radius: 10px;
    margin-bottom: 20px;
    height: 134px;
    padding: 22px 36px;
`

const IcwItemInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-des {
        color: #808589;
        padding-top: 8px;
    }
`

const IcwNewsImg = styled.img.attrs({
    src: `${icwNewsImg}`
})`
    width: 100px;
    height: 90px;
`

const IcwParticipantImg = styled.img.attrs({
    src: `${icwParticipantImg}`
})`
    width: 100px;
    height: 90px;
`

const IcwAwardListImg = styled.img.attrs({
    src: `${icwAwardListImg}`
})`
    width: 100px;
    height: 90px;
`

const IcwIcwNewsTextImg = styled.img.attrs({
    src: `${icwNewsText}`
})`
    width: 35px;
    height: 17px;
    margin-right: 8px;
`

const IcwParticipantTextImg = styled.img.attrs({
    src: `${icwParticipantText}`
})`
    width:  71px;
    height: 17px;
    margin-right: 8px;
`

const IcwAwardListTextImg = styled.img.attrs({
    src: `${icwAwardListText}`
})`
    width: 71px;
    height: 17px;
    margin-right: 8px;
`
