import { SET_STATE, initialState } from '../actions/complianceTipsActions'

export default function (state = initialState, action) {
    const {type, data} = action;
    switch (type) {
        case SET_STATE: {
            return {
                ...data
            }
        }
        default:
            return state
    }
}
