import ccmsService from './ccmsService.js'

async function hideOptionMenu() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (!(/micromessenger/i.test(userAgent) && /wxwork/i.test(userAgent))) {
        return;
    }
    const jsApiList = [
        'hideOptionMenu',
        'previewImage',
        'previewFile',
    ];
    const obj = await this.getSignature();
    if (!obj) {
        return
    }
    const config = {
        beta: true,
        debug: false,
        appId: obj.appId,
        timestamp: `${obj.timestamp}`,
        nonceStr: obj.nonceStr,
        signature: obj.signature,
        jsApiList,
    };
    window.wx.config(config);
    window.wx.error((res) => {
        window.console.log('config error', res);
    });
    
    window.wx.ready(() => {
        window.wx.hideOptionMenu();
    });
}

async function getSignature() {
    const currentUrl = window.location.href;
    let wxSignature;
    const result = await ccmsService.getWxSignature(currentUrl);
    if (result && result.data) {
        wxSignature = result.data;
    }
    return wxSignature;
}

export default {
    getSignature,
    hideOptionMenu,
};