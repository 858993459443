import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { message } from 'antd'
import { Input, Image, InfiniteScroll, List, DotLoading } from 'antd-mobile'

// import { CheckIcon, ComplianceTipsCategoryIcon, ComplianceNotDataIcon } from '../../common/icon'
import { CheckIcon, ComplianceNotDataIcon } from '../../common/icon'
import ccmsService from '../../services/ccmsService'
import { COMPLIANCE_NEWS_TYPE } from '../../common/const'
// import { formatDate } from '../../common/utils'
import wechatShareService from '../../services/wechatShareService'

import store from '../../store'
import { setState } from '../../actions/complianceTipsActions'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagination: {
                pageSize: 10,
                pageNum: 1,
                total: 0,
            },
            search: {
                titleSearch: '',
                categoryId: 0,
            },
            complianceTipsList: [],
            complianceTipsCategoryOptions: [],
            isSelectVisible: false,
            selectedCategoryName: '全部合规提示',
            hasMore: false,
            detailId: 0
        }
    }

    componentDidMount() {
        window.addEventListener('popstate', this.closeWindow)
        wechatShareService.hideOptionMenu()
        store.dispatch(updateIsVisibleLanguageSwitch(false))

        let cacheData = store.getState().complianceTipsData
        this.setState({...cacheData}, () => {
            if (JSON.stringify(cacheData.complianceTipsCategoryOptions) === '[]') {
                // this.getAllComplianceTipsCategory()
            }
            if (document.getElementById(this.state.detailId)) {
                setTimeout(() => {
                    document.getElementById(this.state.detailId).scrollIntoView()
                }, 1);
            }
        })
        if (this.props.location.state?.isShowDetailFail) {
            this.onClickComplianceTipsFilterIcon()
            message.warn(COMPLIANCE_NEWS_TYPE.ENUM.COMPLIANCE_TIPS.label + intl.get('NOT_EXIST'))
        } 
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.closeWindow)
        store.dispatch(setState(this.state))
        store.dispatch(updateIsVisibleLanguageSwitch(true))
    }

    getAllComplianceTipsCategory = async() => {
        let response = await ccmsService.getAllComplianceTipsCategory()
        if (response.code === 200) {
            let categoryList = response.data.map((item) => {
                return { value: item.id + '', label: item.name }
            })
            categoryList.unshift({value: '0', label: '全部合规提示'})
            this.setState({
                complianceTipsCategoryOptions: [categoryList]
            })
        }
    }

    onConfirmComplianceTipsCategorySelect = (value, extend) => {
        if (value === this.state.search.categoryId.toString()) {
            this.setState({isSelectVisible: false})
        } else {
            let search = Object.assign({}, this.state.search, { categoryId: Number(value[0]) })
            this.setState({
                search,
                pagination: {
                    pageSize: 10,
                    pageNum: 1,
                    total: 0,
                },
                complianceTipsList: [],
                hasMore: true,
                isSelectVisible: false,
                selectedCategoryName: extend.items[0].label
            })
        }
    }

    onChangeComplianceTipsTitleFilter = value => {
        let search = Object.assign({}, this.state.search, { titleSearch: value.trim() })
        this.setState({search})
    }

    onClearComplianceTipsTitleFilter = () => {
        this.setState({
            pagination: {
                pageSize: 10,
                pageNum: 1,
                total: 0,
            },
            complianceTipsList: []
        }, () => {
            this.getComplianceTipsList()
        })
    }

    onClickComplianceTipsFilterIcon = () => {
        this.setState({
            pagination: {
                pageSize: 10,
                pageNum: 1,
                total: 0,
            },
            complianceTipsList: [],
            hasMore: true
        })
    }

    getComplianceTipsList = async () => {
        let request = {
            pageSize: this.state.pagination.pageSize,
            pageNumber: this.state.pagination.pageNum,
            complianceType: COMPLIANCE_NEWS_TYPE.ENUM.COMPLIANCE_TIPS.value
        }
        if (this.state.search.titleSearch) {
            request.title = this.state.search.titleSearch
        }
        if (this.state.search.categoryId !== 0) {
            request.categoryId = this.state.search.categoryId
        }

        let response = await ccmsService.getComplianceNewsList(request)
        if (response.code === 200) {
            let pagination = {}
            pagination.pageNum = response.data.pageNum < response.data.totalPage ? ++response.data.pageNum : response.data.pageNum
            pagination.pageSize = response.data.pageSize
            pagination.total = response.data.total
            let complianceTipsList = this.state.complianceTipsList.concat(response.data.list)
            this.setState({
                complianceTipsList,
                pagination
            })
        }
    }

    loadMore = async () => {
        await this.getComplianceTipsList().then(() => {
            if (this.state.pagination.total <= this.state.complianceTipsList.length) {
                this.setState({hasMore: false})
            } else {
                this.setState({hasMore: true})
            }
        })
    }

    onClickComplianceTipsDetail = (id) => {
        this.setState({detailId: id}, () => {
            this.props.history.push({ pathname: `/compliance-tips/${id}` })
        })
    }

    closeWindow = () => {
        if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
            window.WeixinJSBridge.call('closeWindow');
        }
    }

    render() {
        document.title = intl.get('COMPLIANCE_TIPS_LIST_TITLE')

        const InfiniteScrollContent = (props) => {
            let hasMore = props.hasMore
            return (
                this.state.complianceTipsList.length > 0 &&
                <InfiniteScrollContentContainer>
                    {hasMore ? (
                        <>
                            <span>{intl.get('LOADING')}</span>
                            <DotLoading />
                        </>
                    ) : (
                        <span>{intl.get('NO_MORE_DATA')}</span>
                    )}
                </InfiniteScrollContentContainer>
            )
        }

        return (
            <>
                {/* <ComplianceTipsCategorySelect>
                    <Button fill='none' onClick={() => this.setState({isSelectVisible: true})}>
                        <Space>
                            {this.state.selectedCategoryName}
                            <SelectIcon style={{ marginTop: -3, marginLeft: -5 }} />
                        </Space>
                    </Button>
                    <Picker
                        value={this.state.search.categoryId.toString()}
                        columns={this.state.complianceTipsCategoryOptions}
                        visible={this.state.isSelectVisible}
                        onClose={() => this.setState({isSelectVisible: false})}
                        onConfirm={this.onConfirmComplianceTipsCategorySelect}/>
                </ComplianceTipsCategorySelect> */}
                <ComplianceTipsContainer>
                    <ComplianceTipsFilter>
                        <ComplianceTipsTitleFilter>
                            <Input placeholder={intl.get('PLEASE_ENTER_WANT_QUERY_TIPS_NAME')}
                                value={this.state.search.titleSearch}
                                clearable={true}
                                onClear={this.onClearComplianceTipsTitleFilter}
                                onEnterPress={this.onClickComplianceTipsFilterIcon}
                                onChange={this.onChangeComplianceTipsTitleFilter} />
                            <CheckIcon className='search-btn' onClick={this.onClickComplianceTipsFilterIcon}/>
                        </ComplianceTipsTitleFilter>
                    </ComplianceTipsFilter>
                    <ComplianceTipsList>
                        {
                            this.state.complianceTipsList.length > 0 ?
                            <List>
                                {this.state.complianceTipsList.map((item, index) => (
                                    <List.Item key={index} clickable={false} onClick={this.onClickComplianceTipsDetail.bind(this, item.id)} arrow={false}>
                                        <ComplianceTipsListItem id={item.id}>
                                            <div>
                                                {
                                                    item.coverImg && <Image src={item.coverImg} fit='contain' />
                                                }
                                            </div>
                                            <div className="itemContent">
                                                <div className='title'>{item.title}</div>
                                                {/* <div className="footer">
                                                    <ComplianceTipsCategoryIcon style={{ marginTop: -5}}/>
                                                    <span className='categoryName'>{item.categoryName}</span>
                                                    <span className='pubilshDate'>{formatDate('YYYY-mm-dd', new Date(item.publishDate))}</span>
                                                </div> */}
                                            </div>
                                        </ComplianceTipsListItem>
                                    </List.Item>
                                ))}
                            </List>
                            :
                            <ListNoDataStatusContainer>
                                <ComplianceNotDataIcon/>
                                <div className='message'>{intl.get('COMPLIANCE_TIPS_NOT_FOUND')}</div>
                            </ListNoDataStatusContainer>
                        }
                        <InfiniteScroll loadMore={this.loadMore} hasMore={this.state.hasMore}>
                            <InfiniteScrollContent hasMore={this.state.hasMore} />
                        </InfiniteScroll>
                    </ComplianceTipsList>
                </ComplianceTipsContainer>
            </>
        )
    }
}

export default withRouter(Index)

const ComplianceTipsContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

const ComplianceTipsFilter = styled.div`
    display: flex;
`

// const ComplianceTipsCategorySelect = styled.div`
//     display: flex;
//     background-color: white;
//     justify-content: center;
//     height: 56px;
// `

const ComplianceTipsTitleFilter = styled.div`
    display: flex;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #E6EFF8;
    height: 44px;
    width: 100%;
    align-items: center;
    margin: 11px 16px 0;
    .adm-input {
        margin-left: 12px;
        background-color: white;
    }
    .search-btn {
        margin-right: 19px;
    }
`

const ComplianceTipsList = styled.div`
    margin-top: 12px;
    overflow-x: scroll;
    overflow-y: auto;
    height: calc(100vh - 183px);
    -webkit-overflow-scrolling: touch;
    .adm-list {
        --adm-color-background: none;
        --border-top: 0;
        --border-inner: 0;
        --border-bottom: 0;
        --padding-right: 0;
        --padding-left: 0;
        .adm-list-item-content-main {
            padding: 8px 0;
        }
    }
`

const ComplianceTipsListItem = styled.div`
    display: block;
    align-items: center;
    background-color: white;
    margin: 0 16px;
    border-radius: 8px;
    overflow: hidden;
    .itemContent {
        padding: 20px 12px;
        .title {
            font-weight: bold;
        }
        .footer {
            margin-top: 10px;
            font-size: 15px;
            opacity: 0.5;
            .categoryName {
                margin-left: 7px;
            }
            .pubilshDate {
                float: right;
                margin-right: 5px;
            }
        }
    }
`

const InfiniteScrollContentContainer = styled.div`
`

const ListNoDataStatusContainer = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 10vh;
    .message {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.5;
    }
`