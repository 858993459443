import { BlobServiceClient } from '@azure/storage-blob'
import ccmsService from './ccmsService'

async function getFileUrlFromAzure (fullName) {
    var response = await ccmsService.getSAS()
    if (response.code === 200) {
        let accoutName = response.data.accoutName
        let endPorintStuffix = response.data.endPorintStuffix
        let token = response.data.token
        let containerName = response.data.containerName

        const blobSasUrl = `https://${accoutName}.blob.${endPorintStuffix}/?${token}`
        const blobServiceClient = new BlobServiceClient(blobSasUrl)

        const blockBlobClient = blobServiceClient.getContainerClient(containerName).getBlockBlobClient(fullName)
        return blockBlobClient.url
    }
}

export default {
    getFileUrlFromAzure,
}