import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Typography, Button } from 'antd'

import ccmsService from '../../services/ccmsService'
import wechatShareService from '../../services/wechatShareService'

import store from '../../store'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'
const { Title } = Typography

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      search: {},
      detail: {},
    }
  }

  componentDidMount() {
    window.addEventListener('popstate', this.closeWindow)
    wechatShareService.hideOptionMenu()
    store.dispatch(updateIsVisibleLanguageSwitch(false))
    let list = this.state.list
    if (sessionStorage.getItem('RestrictivePartnerList')) {
      list = JSON.parse(sessionStorage.getItem('RestrictivePartnerList'))
    }
    let search = this.state.search
    if (sessionStorage.getItem('RestrictivePartnerSearch')) {
      search = JSON.parse(sessionStorage.getItem('RestrictivePartnerSearch'))
    }
    this.setState({ list, search })
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.closeWindow)
    sessionStorage.setItem('RestrictivePartnerList', JSON.stringify(this.state.list));
    store.dispatch(updateIsVisibleLanguageSwitch(true))
  }

  closeWindow = () => {
    if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
      window.WeixinJSBridge.call('closeWindow');
    }
  }

  handleToDetail = async (item) => {
    const { type, name, companyName, organizationCode, queryKeyword } = this.state.search
    const request = {
      type,
      name: type === 1 ? companyName : name,
      organizationCode,
      id: item.id,
      queryKeyword,
    }
    let response = await ccmsService.getRestrictedPartner(request)
    const { data } = response
    this.setState({
      detail: {
        isCanCooperate: data.isCanCooperate,
        type: data.type,
        name: data.name,
        restrictionType: data.restrictionType,
        restrictionStartTime: data.restrictionStartTime,
        restrictionEndTime: data.restrictionEndTime,
      }
    })
    sessionStorage.setItem('RestrictivePartnerDetail', JSON.stringify(this.state.detail));
    this.props.history.push({
      pathname: `/restrictive-partner-detail`,
    })
  }
  render() {
    document.title = intl.get('RESTRICTION_PARTNER_TITLE')

    return (
      <PageContainer>
        <Title level={5}>查询结果</Title>
        <TitleDescription>根据您输入的关键词查询到下述合作方信息</TitleDescription>
        <ListContainer>
          {
            this.state.list.map((item, index) => {
              return (
                <ListItem key={index}>
                  <BlueText>{item.name}</BlueText>
                  <Button type="primary" onClick={() => this.handleToDetail(item)}>查看</Button>
                </ListItem>
              )
            })
          }
        </ListContainer>
        <BackButton>
          <Button type="primary" block={true} onClick={() => {
            this.props.history.push({ pathname: `/restrictive-partner-search` })
          }}>返回首页</Button>
        </BackButton>
      </PageContainer>
    )
  }
}

export default withRouter(Index)

const PageContainer = styled.div`
  margin: 16px;
  padding: 16px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(223,230,234,1);
  font-size: 14px;
`
const TitleDescription = styled.div`
  color: #7B7B7B;
`
const BackButton = styled.div`
  margin: 52px 0 28px;
  button {
    font-weight: bold;
    border-radius: 4px;
  }
`
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px dashed rgba(199,201,207,1);
  &:last-child {
    border-bottom: none;
  }
  button {
    font-size: 14px;
    height: 100%;
    width: 64px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
  }

`

const BlueText = styled.div`
  font-size: 14px;
  color: #1890ff;
`
