import axios from './axios'
import 'url-search-params-polyfill'

class CcmsService {
    constructor() {
        this.axios = axios
        this.params = new URLSearchParams(window.location.search)
    }

    // login by wechat work code
    wechatWorkLogin = (code) => {
        return this.axios.get(`/portal/auth/oauth/wechat_work?code=${code}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    // get wx signature
    getWxSignature = (url) => {
        return this.axios.get(`/portal/configuration/wx/signature?url=${encodeURIComponent(url.split('#')[0])}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    // get azure upload token
    getSAS = () => {
        return this.axios.get('/portal/inform/sas/get', { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    // get kaptcha code
    getKaptchaCode = (request) => {
        return this.axios.post('/portal/kaptcha/get', request, { headers: { isLoading: false } }).then(response => {
            let data = response.data
            return data
        })
    }

    // save inform info
    submitInform(request) {
        return this.axios.post('/portal/inform/save', request, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    // check is inform number exist
    checkInformNumber = (informNumber) => {
        return this.axios.get(`/portal/inform/check?informNumber=${informNumber}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }


    // save extra inform info
    submitInformExtra(request) {
        return this.axios.post('/portal/inform/extra/save', request, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    // check whether the value of an item in url params is true
    isTruthyInQuery(name) {
        const item = this.params.get(name)
        if (item) {
            return item.replace(/\//g, '') === 'true'
        } else {
            return false
        }
    }

    // get the value of an item in url params
    getQueryFieldValue(fieldName) {
        const item = this.params.get(fieldName)
        return item || ''
    }

    searchCommonStatistic (request) {
        return this.axios.post('/admin/statistics/inform', request, { headers: { isLoading: true }}).then(response => {
            return response.data
        })
    }

    getComplianceNewsList(request) {
        let headers = { isLoading: false }
        if (request.pageNumber === 1) {
            headers.isLoading = true
        }
        return this.axios.get('/portal/compliance-news/list', { headers, params: { ...request } }).then(response => {
            let data = response.data
            return data
        })
    }

    getComplianceNewsDetail(id) {
        return this.axios.get(`/portal/compliance-news/${id}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getPreviewComplianceNewsDetail(id) {
        return this.axios.get(`/portal/compliance-news/preview/${id}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getAllComplianceTipsCategory() {
        return this.axios.get('/portal/compliance-news/category/all', { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getRestrictedPartner(request) {
        return this.axios.get('/portal/restricted-vendor/query', { headers: { isLoading: true }, params: { ...request } }).then(response => {
            let data = response.data
            return data
        })
    }

    getRestrictedPartnerPermission() {
        return this.axios.get('/portal/restricted-vendor/check-permission', { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    // 诚信文化周
    getIcwList() {
        return this.axios.get('/portal/integrity-culture-week/list', { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getPreviewIcwSummary(id) {
        return this.axios.get(`/portal/integrity-culture-week/summary/preview/${id}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getIcwSummary(id) {
        return this.axios.get(`/portal/integrity-culture-week/summary/${id}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getPreviewIcwDetail(id) {
        return this.axios.get(`/portal/integrity-culture-week/detail/preview/${id}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getIcwDetail(id) {
        return this.axios.get(`/portal/integrity-culture-week/detail/${id}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    getIcwProfile() {
        return this.axios.get('/portal/integrity-culture-week/profile', { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data;
        })
    }

    authH5Game = (code) => {
        return this.axios.get(`/portal/auth/oauth/h5_game?code=${code}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }

    supplyH5GameMarket = (token, market) => {
        return this.axios.get(`/portal/auth/oauth/h5_game/supply_market?token=${token}&market=${market}`, { headers: { isLoading: true } }).then(response => {
            let data = response.data
            return data
        })
    }
}

const ccmsService = new CcmsService()

export default ccmsService
