import {
    ADD_IS_CHECKED_CURRENT_CONTENT,
    UPDATE_IS_CHECKED_CURRENT_CONTENT,
    ADD_IS_CHECKED_PRIVACY_POLICY,
    UPDATE_IS_CHECKED_PRIVACY_POLICY,
    ADD_HAS_READ_PRIVACY_POLICY,
    UPDATE_HAS_READ_PRIVACY_POLICY,
    UPDATE_IS_VISIBLE_LANGUAGE_SWITCH,
    UPDATE_PC_CONTAINER_WIDTH
} from '../actions/commonActions'

const initialState = {
    isCheckedCurrentContent: false,
    isCheckedPrivacyPolicy: false,
    hasReadPrivacyPolicy: false,
    isVisibleLanguageSwitch: true,
    pcContainerWidth: 650
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_IS_CHECKED_CURRENT_CONTENT: {
            return {
                ...state,
                isCheckedCurrentContent: action.payload.isCheckedCurrentContent
            }
        }

        case UPDATE_IS_CHECKED_CURRENT_CONTENT: {
            return {
                ...state,
                isCheckedCurrentContent: action.payload.isCheckedCurrentContent
            }
        }

        case ADD_IS_CHECKED_PRIVACY_POLICY: {
            return {
                ...state,
                isCheckedPrivacyPolicy: action.payload.isCheckedPrivacyPolicy
            }
        }

        case UPDATE_IS_CHECKED_PRIVACY_POLICY: {
            return {
                ...state,
                isCheckedPrivacyPolicy: action.payload.isCheckedPrivacyPolicy
            }
        }

        case ADD_HAS_READ_PRIVACY_POLICY: {
            return {
                ...state,
                hasReadPrivacyPolicy: action.payload.hasReadPrivacyPolicy
            }
        }

        case UPDATE_HAS_READ_PRIVACY_POLICY: {
            return {
                ...state,
                hasReadPrivacyPolicy: action.payload.hasReadPrivacyPolicy
            }
        }

        case UPDATE_IS_VISIBLE_LANGUAGE_SWITCH: {
            return {
                ...state,
                isVisibleLanguageSwitch: action.payload.isVisibleLanguageSwitch
            }
        }

        case UPDATE_PC_CONTAINER_WIDTH: {
            return {
                ...state,
                pcContainerWidth: action.payload.pcContainerWidth
            }
        }

        default:
            return state;
    }
}
