let CCMS_APP_SERVICE_BASE_URL = 'http://192.168.191.63:8282'
let CCMS_APP_WECHAT_WORK_COPR_ID = 'wx42cf5e73876fe3b9'
let RESTRICTED_PARTNER_POLICY_DETAIL = 'https://cnazccmsblob.blob.core.chinacloudapi.cn/build/files/第三方合规管理 - 限制合作方管理-202311.pdf'
let DOWNLOAD_APPLICATION_EXCEL = 'https://cnazccmsblob.blob.core.chinacloudapi.cn/build/files/异议申请表-202310.xlsx'
let DOWNLOAD_APPLICATION_EXCEL_TEMPLATE = 'https://cnazccmsblob.blob.core.chinacloudapi.cn/build/files/异议申请表模板样例-202310.pdf'

switch (process.env.REACT_APP_SECRET_ENV) {
    case 'development':
        CCMS_APP_SERVICE_BASE_URL = 'http://192.168.191.68:8282'
        break;
    case 'stage':
        CCMS_APP_SERVICE_BASE_URL = 'https://ccms-test.mcdonalds.com.cn/'
        break;
    case 'production':
        CCMS_APP_SERVICE_BASE_URL = 'https://ccms.mcdonalds.com.cn/'
        RESTRICTED_PARTNER_POLICY_DETAIL = 'https://cnazccmsblobpro.blob.core.chinacloudapi.cn/build/files/第三方合规管理 - 限制合作方管理-202311.pdf'
        DOWNLOAD_APPLICATION_EXCEL = 'https://cnazccmsblobpro.blob.core.chinacloudapi.cn/build/files/异议申请表-202310.xlsx'
        DOWNLOAD_APPLICATION_EXCEL_TEMPLATE = 'https://cnazccmsblobpro.blob.core.chinacloudapi.cn/build/files/异议申请表模板样例-202310.pdf'
        break;
    default:
        CCMS_APP_SERVICE_BASE_URL = 'http://192.168.191.63:8282'
}

export default {
    serviceBaseUrl: CCMS_APP_SERVICE_BASE_URL,
    wxWorkCorpId: CCMS_APP_WECHAT_WORK_COPR_ID,
    restrictedPartnerPolicyDetail: RESTRICTED_PARTNER_POLICY_DETAIL,
    downloadApplicationExcel: DOWNLOAD_APPLICATION_EXCEL,
    downloadApplicationExcelTemplate: DOWNLOAD_APPLICATION_EXCEL_TEMPLATE,
}
