export const SET_STATE = 'COMPLIANCE_POLICY_SET_STATE'

export const initialState = {
    pagination: {
        pageSize: 15,
        pageNum: 1,
        total: 0,
    },
    titleSearch: '',
    compliancePolicyList: [],
    hasMore: true,
    detailId: 0
}

export function setState(data) {
    return {
        type: SET_STATE,
        data: { ...data }
    }
}