export const SET_STATE = 'COMPLIANCE_TIPS_SET_STATE'

export const initialState = {
    pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
    },
    search: {
        titleSearch: '',
        categoryId: 0,
    },
    complianceTipsList: [],
    complianceTipsCategoryOptions: [],
    isSelectVisible: false,
    selectedCategoryName: '全部合规提示',
    hasMore: true,
    detailId: 0
}

export function setState(data) {
    return {
        type: SET_STATE,
        data: { ...data }
    }
}