import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Button, Checkbox, Modal } from 'antd'

import store from '../store'
import {
    updateIsCheckedCurrentContent,
    updateIsCheckedPrivacyPolicy,
    updateHasReadPrivacyPolicy
} from '../actions/commonActions'

const MobileNoticeContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const NoticeHeader = styled.div`
    height: 10px;
    width: 100vw;
    background-color: #00182e;
    margin-top: -1px;
    z-index: 9;
`

const NoticeInfoContainer = styled.div`
    width: 100vw;
    padding: 0 16px;
    margin-top: -9px;
    z-index: 10;
`

const NoticeContentContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 20px;
`

const NoticeTitle = styled.h2`
    height: 26px;
    font-size: 18px;
    text-align: left;
    font-weight: bold;
`

const NoticeContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`

const AgreementContainer = styled.div`
    display: flex;
    margin-top: 10px;
`

const AgreementLabel = styled.span`
    margin-left: 10px;
`

const PrivacyPolicySpan = styled.span`
    color: #1890ff;
`

const PrivacyPolicyContainer = styled.div`
    margin: 6px 0;
`

const PrivacyPolicyTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
`

const PrivacyPolicyEffectiveDate = styled.div`
    text-align: right;
    margin-bottom: 10px;
`

const PrivacyPolicyContent = styled.div`
    overflow: auto;
    height: calc(100vh - 200px);
    margin-top: 10px;
    padding-right: 6px
`

const PrivacyPolicyPromptModalContainer = styled.div`
    margin: 20px 0;
    text-align: center;
`

class MobileNotice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCheckedCurrentContent: false,
            isCheckedPrivacyPolicy: false,
            hasReadPrivacyPolicy: false,
            isPrivacyPolicyPromptModalVisible: false,
            isPrivacyPolicyModalVisible: false,
            isNextStepButtonEnable: false,
            privacyPolicyContainerHeight: 0
        }
    }

    componentDidMount() {
        document.body.scrollIntoView()
        this.setState({
            isCheckedCurrentContent: store.getState().commonData.isCheckedCurrentContent,
            isCheckedPrivacyPolicy: store.getState().commonData.isCheckedPrivacyPolicy,
            hasReadPrivacyPolicy: store.getState().commonData.hasReadPrivacyPolicy
        }, this.setIsNextStepButtonEnable)

        this.setState({ privacyPolicyContainerHeight: document.body.clientHeight - 200 })
    }

    onChangeCurrentContent = e => {
        store.dispatch(updateIsCheckedCurrentContent(e.target.checked))
        this.setState({
            isCheckedCurrentContent: e.target.checked
        }, this.setIsNextStepButtonEnable)
    }

    onChangePrivacyPolicy = e => {
        store.dispatch(updateIsCheckedPrivacyPolicy(e.target.checked))
        if (!this.state.hasReadPrivacyPolicy) {
            this.setState({
                isCheckedPrivacyPolicy: false,
                isPrivacyPolicyPromptModalVisible: true
            })
        } else {
            this.setState({
                isCheckedPrivacyPolicy: e.target.checked
            }, this.setIsNextStepButtonEnable)
        }
    }

    setIsNextStepButtonEnable = () => {
        if (this.state.isCheckedCurrentContent && this.state.isCheckedPrivacyPolicy) {
            this.setState({ isNextStepButtonEnable: true })
        } else {
            this.setState({ isNextStepButtonEnable: false })
        }
    }

    onClickPrivacyPolicySpan = () => {
        store.dispatch(updateHasReadPrivacyPolicy(true))
        this.setState({ isPrivacyPolicyModalVisible: true, hasReadPrivacyPolicy: true })
    }

    closePrivacyPolicyPromptModal = () => {
        this.setState({ isPrivacyPolicyPromptModalVisible: false })
    }

    closePrivacyPolicyModal = () => {
        this.setState({ isPrivacyPolicyModalVisible: false })
    }

    onClickNextStep = () => {
        sessionStorage.setItem('previousPage', 'notice')
        this.props.history.push({ pathname: '/form' })
    }

    render() {
        return (
            <MobileNoticeContainer className="mobile-notice-container">
                <NoticeHeader />
                <NoticeInfoContainer>
                    <NoticeContentContainer>
                        <NoticeTitle>{intl.get('INFORM_NOTICE')}</NoticeTitle>
                        <NoticeContent>
                            {
                                Array(6).fill().map((_, i) => (
                                    <div key={i} dangerouslySetInnerHTML={{ __html: intl.get(`INFORM_NOTICE_LABEL${i + 1}`) }} />
                                ))
                            }
                        </NoticeContent>
                        <AgreementContainer>
                            <Checkbox onChange={this.onChangeCurrentContent} checked={this.state.isCheckedCurrentContent} />
                            <AgreementLabel>{intl.get('AGREEMENT_LABEL1')}</AgreementLabel>
                        </AgreementContainer>
                        <AgreementContainer>
                            <Checkbox onChange={this.onChangePrivacyPolicy} checked={this.state.isCheckedPrivacyPolicy} />
                            <AgreementLabel>
                                {intl.get('AGREEMENT_LABEL2')}
                                <PrivacyPolicySpan onClick={this.onClickPrivacyPolicySpan}>
                                    {intl.get('PRIVACY_POLICY')}
                                </PrivacyPolicySpan>
                                {intl.get('PRIVACY_POLICY_END')}
                            </AgreementLabel>
                        </AgreementContainer>
                        <div style={{ marginTop: 20 }}>
                            <Button disabled={!this.state.isNextStepButtonEnable} block type="primary" shape="round" onClick={this.onClickNextStep}>{intl.get('BUTTON_GO_FORM')}</Button>
                        </div>

                        <Modal
                            maskClosable={false}
                            centered={true}
                            visible={this.state.isPrivacyPolicyModalVisible}
                            footer={null}
                            onCancel={this.closePrivacyPolicyModal}
                            width={"90vw"}>
                            <PrivacyPolicyContainer>
                                <PrivacyPolicyTitle>{intl.get('PRIVACY_POLICY_TITLE')}</PrivacyPolicyTitle>
                                <PrivacyPolicyContent>
                                    <PrivacyPolicyEffectiveDate>{intl.get('PRIVACY_POLICY_EFFECTIVE_DATE')}</PrivacyPolicyEffectiveDate>
                                    {
                                        Array(8).fill().map((_, i) => (
                                            <div key={i} dangerouslySetInnerHTML={{ __html: intl.get('PRIVACY_POLICY_LABEL' + i) }} />
                                        ))
                                    }
                                </PrivacyPolicyContent>
                            </PrivacyPolicyContainer>
                        </Modal>

                        <Modal
                            maskClosable={false}
                            centered={true}
                            visible={this.state.isPrivacyPolicyPromptModalVisible}
                            footer={null}
                            onCancel={this.closePrivacyPolicyPromptModal}
                            width={300}>
                            <PrivacyPolicyPromptModalContainer>
                                <div>{intl.get('PRIVACY_POLICY_PROMPT_LABEL1')}</div>
                                <div>{intl.get('PRIVACY_POLICY_PROMPT_LABEL2')}</div>
                            </PrivacyPolicyPromptModalContainer>
                        </Modal>
                    </NoticeContentContainer>
                </NoticeInfoContainer>
            </MobileNoticeContainer>
        )
    }
}

export default withRouter(MobileNotice)
