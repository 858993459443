import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import { Modal, Button } from 'antd'

import store from '../store'
import { QuestionMarkIcon, PcHomeHeaderImage } from '../common/icon'
import {
    updateIsCheckedCurrentContent,
    updateIsCheckedPrivacyPolicy,
    updateHasReadPrivacyPolicy
} from '../actions/commonActions'

const PcHomeContainer = styled.div`
    width: 1024px;
    min-height: calc(100vh - 140px);
`

const PlatformSectionContainer = styled.div`
    width: 1024px;
`

const PlatformContentContainer = styled.div`
    background-color: white;
    padding: 30px;
`

const PlatformTitle = styled.div`
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
`

const QuestionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`

const QuestionLabel = styled.p`
    color: #1890ff;
    font-weight: bold;
    margin-left: 10px;
`

const QuestionContentContainer = styled.div`
    margin: 6px 0;
`

const FrequentlyQuestionTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
`

const FrequentlyQuestionContent = styled.div`
    overflow: auto;
    height: calc(100vh - 200px);
    margin-top: 10px;
    padding-right: 6px;
`

const NextStepButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
`

class PcHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isQuestionContentModalVisible: false,
            questionContentContainerHeight: 0
        }
    }

    componentDidMount() {
        document.body.scrollIntoView()
        store.dispatch(updateIsCheckedCurrentContent(false))
        store.dispatch(updateIsCheckedPrivacyPolicy(false))
        store.dispatch(updateHasReadPrivacyPolicy(false))
        this.setState({
            questionContentContainerHeight: document.body.clientHeight - 200
        })
    }

    OnClickQuestionContainer = () => {
        this.setState({
            isQuestionContentModalVisible: true
        })
    }

    closeQuestionContentModal = () => {
        this.setState({
            isQuestionContentModalVisible: false
        })
    }

    onClickNextStep = () => {
        sessionStorage.setItem('previousPage', 'home')
        this.props.history.push({ pathname: '/notice' })
    }

    render() {
        return (
            <PcHomeContainer className="pc-home-container">
                <PcHomeHeaderImage />
                <PlatformSectionContainer>
                    <PlatformContentContainer>
                        <PlatformTitle>{intl.get('PLATFORM_TITLE')}</PlatformTitle>
                        <div>
                            {
                                Array(2).fill().map((_, i) => (
                                    <div key={i} dangerouslySetInnerHTML={{ __html: intl.get('PLATFORM_CONTENT_LABEL' + (i + 1)) }} />
                                ))
                            }
                        </div>
                        <QuestionContainer>
                            <QuestionMarkIcon className="cursor-pointer" onClick={this.OnClickQuestionContainer} />
                            <QuestionLabel className="cursor-pointer" onClick={this.OnClickQuestionContainer}>{intl.get('FREQUENTLY_QUESTION')}</QuestionLabel>
                        </QuestionContainer>

                        <NextStepButtonContainer>
                            <Button
                                type="primary"
                                shape="round"
                                style={{ width: 190 }}
                                onClick={this.onClickNextStep}>{intl.get('BUTTON_NEXT_STEP')}</Button>
                        </NextStepButtonContainer>
                        <Modal
                            maskClosable={false}
                            centered={true}
                            visible={this.state.isQuestionContentModalVisible}
                            footer={null}
                            onCancel={this.closeQuestionContentModal}
                            width={1024}>
                            <QuestionContentContainer>
                                <FrequentlyQuestionTitle>{intl.get('FREQUENTLY_QUESTION_TITLE')}</FrequentlyQuestionTitle>
                                <FrequentlyQuestionContent>
                                    {
                                        Array(12).fill().map((_, i) => (
                                            <div key={i} dangerouslySetInnerHTML={{ __html: intl.get('FREQUENTLY_QUESTION_LABEL' + (i + 1)) }} />
                                        ))
                                    }
                                </FrequentlyQuestionContent>
                            </QuestionContentContainer>
                        </Modal>
                    </PlatformContentContainer>
                </PlatformSectionContainer>
            </PcHomeContainer>
        )
    }
}

export default withRouter(PcHome)
