import styled from 'styled-components'

import questionMarkIcon from '../assets/images/question_mark_icon.svg'
import closeIcon from '../assets/images/refresh_icon.svg'
import arrowDownIcon from '../assets/images/arrow_down_icon.svg'
import uploadButtonIcon from '../assets/images/upload_button_icon.svg'
import uploadFolderIcon from '../assets/images/upload_folder_icon.svg'
import deleteIcon from '../assets/images/delete_icon.svg'
import refreshIcon from '../assets/images/refresh_icon.svg'
import checkIcon from '../assets/images/check_icon.svg'
import fileTypeDocIcon from '../assets/images/file_type_doc_icon.svg'
import fileTypeDocErrorIcon from '../assets/images/file_type_doc_error_icon.svg'
import fileTypeImageIcon from '../assets/images/file_type_image_icon.svg'
import fileTypeImageErrorIcon from '../assets/images/file_type_image_error_icon.svg'
import fileTypeAudioIcon from '../assets/images/file_type_audio_icon.svg'
import fileTypeAudioErrorIcon from '../assets/images/file_type_audio_error_icon.svg'
import fileTypeVideoIcon from '../assets/images/file_type_video_icon.svg'
import fileTypeVideoErrorIcon from '../assets/images/file_type_video_error_icon.svg'
import fileTypeOtherIcon from '../assets/images/file_type_other_icon.svg'
import fileTypeOtherErrorIcon from '../assets/images/file_type_other_error_icon.svg'
import pcHomeHeaderImage from '../assets/images/pc_home_header.png'
import mobileHomeHeaderImage from '../assets/images/mobile_home_header.png'
import selectIcon from '../assets/images/select_icon.svg'
import complianceTipsCategoryIcon from '../assets/images/compliance_tips_category_icon.svg'
import complianceNotDataIcon from '../assets/images/compliance_not_data_icon.svg'
import error from '../assets/images/error.svg'
import downloadTemplateIcon from '../assets/images/download_template_icon.svg'
import downloadExcelIcon from '../assets/images/download_excel_icon.svg'
import arrowRightIcon from '../assets/images/arrow_right_icon.svg'

export const Error = styled.img.attrs({
    src: `${error}`
})`
    width: 280px;
    height: 240px;
`

export const ComplianceNotDataIcon = styled.img.attrs({
    src: `${complianceNotDataIcon}`
})`
    width: 280px;
    height: 240px;
`

export const SelectIcon = styled.img.attrs({
    src: `${selectIcon}`
})`
    width: 22px;
    height: 20px;
`

export const ComplianceTipsCategoryIcon = styled.img.attrs({
    src: `${complianceTipsCategoryIcon}`
})`
    width: 20px;
    height: 20px;
`

export const QuestionMarkIcon = styled.img.attrs({
    src: `${questionMarkIcon}`
})`
    width: 20px;
    height: 20px;
`

export const CloseIcon = styled.img.attrs({
    src : `${closeIcon}`
})`
    width: 18px;
    height: 18px;
`

export const ArrowDownIcon = styled.img.attrs({
    src : `${arrowDownIcon}`
})`
    height: 6px;
    width: 12px;
    transition: transform .5s;

    &.expand {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
`

export const UploadButtonIcon = styled.img.attrs({
    src: `${uploadButtonIcon}`
})`
    width: 22px;
    height: 22px;
`

export const UploadFolderIcon = styled.img.attrs({
    src: `${uploadFolderIcon}`
})`
    width: 22px;
    height: 20px;
`

export const DeleteIcon = styled.img.attrs({
    src : `${deleteIcon}`
})`
    width: 22px;
    height: 22px;
`
export const DownloadExcelIcon = styled.img.attrs({
    src : `${downloadExcelIcon}`
})`
    width: 20px;
    height: 20px;
`
export const DownloadTemplateIcon = styled.img.attrs({
    src : `${downloadTemplateIcon}`
})`
    width: 20px;
    height: 20px;
`

export const RefreshIcon = styled.img.attrs({
    src: `${refreshIcon}`
})`
    width: 24px;
    height: 24px;
    margin-left: 10px;
`

export const CheckIcon = styled.img.attrs({
    src : `${checkIcon}`
})`
    width: 24px;
    height: 25px;
    margin-left: 16px;
`

export const FileTypeIcon = styled.div`
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;

    &.doc {
        background-image: url(${fileTypeDocIcon});

        &.error {
            background-image: url(${fileTypeDocErrorIcon});
        }
    }

    &.image {
        background-image: url(${fileTypeImageIcon});

        &.error {
            background-image: url(${fileTypeImageErrorIcon});
        }
    }

    &.audio {
        background-image: url(${fileTypeAudioIcon});

        &.error {
            background-image: url(${fileTypeAudioErrorIcon});
        }
    }

    &.video {
        background-image: url(${fileTypeVideoIcon});

        &.error {
            background-image: url(${fileTypeVideoErrorIcon});
        }
    }

    &.other {
        background-image: url(${fileTypeOtherIcon});

        &.error {
            background-image: url(${fileTypeOtherErrorIcon});
        }
    }
`

export const PcHomeHeaderImage = styled.img.attrs({
    src: `${pcHomeHeaderImage}`
})`
    width: 1024px;
    height: 370px;
`

export const MobileHomeHeaderImage = styled.img.attrs({
    src: `${mobileHomeHeaderImage}`
})`
    width: 100%;
    height: auto;
    z-index: 9;
`


export const ArrowRightIcon = styled.img.attrs({
    src: `${arrowRightIcon}`
})`
    width: 8px;
    height: 12px;
`
