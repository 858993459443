export const ADD_LANGUAGE = 'ADD_LANGUAGE'
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'

export function addLanguage(language) {
    return {
        type: ADD_LANGUAGE,
        payload: {
            language
        }
    }
}

export function updateLanguage(language) {
    return {
        type: UPDATE_LANGUAGE,
        payload: {
            language
        }
    }
}
