import { ADD_LANGUAGE, UPDATE_LANGUAGE } from '../actions/languageActions'

const initialState = {
    language: "zh-CN"
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_LANGUAGE: {
            return {
                ...state,
                language: action.payload.language
            }
        }

        case UPDATE_LANGUAGE: {
            return {
                ...state,
                language: action.payload.language
            }
        }

        default:
            return state
    }
}
