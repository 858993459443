import axios from 'axios'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { message, Spin } from 'antd'
import Config from '../config/initEnv'

// set axios baseURL and timeout
const Axios = axios.create({
    baseURL: Config.serviceBaseUrl,
    timeout: 10000,
    headers: { 'isLoading': false }
})

// set axios post header
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// current request count
let requestCount = 0

// show loading
function showLoading() {
    if (requestCount === 0) {
        var dom = document.createElement('div')
        dom.setAttribute('id', 'loading')
        document.body.appendChild(dom)
        ReactDOM.render(<Spin size="large" />, dom)
    }
    requestCount++
}

// hide loading
function hideLoading() {
    requestCount--
    if (requestCount === 0) {
        document.body.removeChild(document.getElementById('loading'))
    }
}

// handle before axios request
Axios.interceptors.request.use(config => {
    if (config.headers.isLoading !== false) {
        showLoading()
    }
    return config
}, err => {
    // check whether the current request is set isLoading in headers
    if (err.config.headers.isLoading !== false) {
        hideLoading()
    }
    return Promise.reject(err)
})

// handle after axios request
Axios.interceptors.response.use(res => {
    // check whether the current request is set isLoading in headers
    if (res.config.headers.isLoading !== false) {
        hideLoading()
    }
    return res
}, err => {
    if (err.config.headers.isLoading !== false) {
        hideLoading()
    }
    if (err.message === 'Network Error') {
        message.warning('Network Error')
    }
    if (err.code === 'ECONNABORTED') {
        message.warning('Network time out, please try again.')
    }
    return Promise.reject(err)
})

Component.prototype.$axios = Axios

export default Axios
