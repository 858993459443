export const ADD_IS_CHECKED_CURRENT_CONTENT = 'ADD_IS_CHECKED_CURRENT_CONTENT'
export const UPDATE_IS_CHECKED_CURRENT_CONTENT = 'UPDATE_IS_CHECKED_CURRENT_CONTENT'
export const ADD_IS_CHECKED_PRIVACY_POLICY = 'ADD_IS_CHECKED_PRIVACY_POLICY'
export const UPDATE_IS_CHECKED_PRIVACY_POLICY = 'UPDATE_IS_CHECKED_PRIVACY_POLICY'
export const ADD_HAS_READ_PRIVACY_POLICY = 'ADD_HAS_READ_PRIVACY_POLICY'
export const UPDATE_HAS_READ_PRIVACY_POLICY = 'UPDATE_HAS_READ_PRIVACY_POLICY'
export const UPDATE_IS_VISIBLE_LANGUAGE_SWITCH = 'UPDATE_IS_VISIBLE_LANGUAGE_SWITCH'
export const UPDATE_PC_CONTAINER_WIDTH = 'UPDATE_PC_CONTAINER_WIDTH'

export function addIsCheckedCurrentContent(isCheckedCurrentContent) {
    return {
        type: ADD_IS_CHECKED_CURRENT_CONTENT,
        payload: {
            isCheckedCurrentContent
        }
    }
}

export function updateIsCheckedCurrentContent(isCheckedCurrentContent) {
    return {
        type: UPDATE_IS_CHECKED_CURRENT_CONTENT,
        payload: {
            isCheckedCurrentContent
        }
    }
}

export function addIsCheckedPrivacyPolicy(isCheckedPrivacyPolicy) {
    return {
        type: ADD_IS_CHECKED_PRIVACY_POLICY,
        payload: {
            isCheckedPrivacyPolicy
        }
    }
}

export function updateIsCheckedPrivacyPolicy(isCheckedPrivacyPolicy) {
    return {
        type: UPDATE_IS_CHECKED_PRIVACY_POLICY,
        payload: {
            isCheckedPrivacyPolicy
        }
    }
}

export function addHasReadPrivacyPolicy(hasReadPrivacyPolicy) {
    return {
        type: ADD_HAS_READ_PRIVACY_POLICY,
        payload: {
            hasReadPrivacyPolicy
        }
    }
}

export function updateHasReadPrivacyPolicy(hasReadPrivacyPolicy) {
    return {
        type: UPDATE_HAS_READ_PRIVACY_POLICY,
        payload: {
            hasReadPrivacyPolicy
        }
    }
}

export function updateIsVisibleLanguageSwitch(isVisibleLanguageSwitch) {
    return {
        type: UPDATE_IS_VISIBLE_LANGUAGE_SWITCH,
        payload: {
            isVisibleLanguageSwitch
        }
    }
}

export function updatePcContainerWidth(pcContainerWidth) {
    return {
        type: UPDATE_PC_CONTAINER_WIDTH,
        payload: {
            pcContainerWidth
        }
    }
}

