import React, { Component } from 'react'
import styled from 'styled-components'
import intl from 'react-intl-universal'

import { ArrowDownIcon } from '../common/icon'

const UploadSupportFileListContainer = styled.div`
    display: flex;
    flex-direction: column;

    &.pc-container {
        .upload-header {
            flex-direction: row;
        }

        .upload-hint {
            max-width: 360px;
        }
    }
`

const UploadHint = styled.p`
    font-size: 12px;
    opacity: 0.8;
    text-align: left;
    max-width: 360px;
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const HeaderLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
`

const HeaderLabel = styled.p`
    font-size: 12px;
    color: #1890ff;
    margin-right: 6px;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ContentLabel = styled.p`
    font-size: 12px;
    opacity: 0.8;
`

class UploadSupportFileListExpand extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pc: this.props.pc ? true : false,
            isUploadSupportFileListExpand: false
        }
    }

    componentDidMount() {
    }

    onCLickUploadSupportFileListHeader = () => {
        this.setState({ isUploadSupportFileListExpand: !this.state.isUploadSupportFileListExpand })
    }

    render() {
        return (
            <UploadSupportFileListContainer className={this.state.pc ? "pc-container" : null}>
                <HeaderContainer className="upload-header">
                    <UploadHint className="upload-hint">{intl.get('INFORM_INFO_UPLOAD_HINT')}</UploadHint>
                    <HeaderLabelContainer onClick={this.onCLickUploadSupportFileListHeader}>
                        <HeaderLabel>{intl.get('INFORM_INFO_UPLOAD_SUPPORT_HINT')}</HeaderLabel>
                        <ArrowDownIcon style={{ marginTop: 2 }} className={this.state.isUploadSupportFileListExpand ? "expand" : null} />
                    </HeaderLabelContainer>
                </HeaderContainer>

                {
                    this.state.isUploadSupportFileListExpand && (
                        <ContentContainer>
                            {
                                Array(10).fill().map((_, i) => (
                                    <ContentLabel key={i}>{intl.get(`INFORM_INFO_UPLOAD_SUPPORT_LABEL${i + 1}`)}</ContentLabel>
                                ))
                            }
                        </ContentContainer>
                    )
                }
            </UploadSupportFileListContainer>
        )
    }
}

export default UploadSupportFileListExpand
