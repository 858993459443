import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import intl from 'react-intl-universal'
import moment from 'moment'
import wechatShareService from '../../services/wechatShareService'
import { DownloadTemplateIcon, DownloadExcelIcon } from '../../common/icon'
import store from '../../store'
import { checkDeviceIsMobile } from '../../common/utils'
import { updateIsVisibleLanguageSwitch } from '../../actions/commonActions'
import Config from '../../config/initEnv'
import { RESTRICTIVE_PARTNER_LIST_OPTIONS } from '../../common/const'
const { RESTRICTIVE_PARTNER_TYPE, RESTRICTIVE_TYPE } = RESTRICTIVE_PARTNER_LIST_OPTIONS

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: {},
    }
  }

  componentDidMount() {
    window.addEventListener('popstate', this.closeWindow)
    wechatShareService.hideOptionMenu()
    store.dispatch(updateIsVisibleLanguageSwitch(false))
    let detail = this.state.detail
    if (sessionStorage.getItem('RestrictivePartnerDetail')) {
      detail = JSON.parse(sessionStorage.getItem('RestrictivePartnerDetail'))
    }
    this.setState({ detail })
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.closeWindow)
    store.dispatch(updateIsVisibleLanguageSwitch(true))
  }

  downloadFile = (url) => {
    if (checkDeviceIsMobile()) {
      const xhr = new XMLHttpRequest()
      xhr.open('HEAD', url, true)
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            const size = xhr.getResponseHeader('Content-Length')
            window.wx.previewFile({
              url,
              size
            })
          }
        }
      }
      xhr.send()
    } else {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  closeWindow = () => {
    if (window.location.hash?.includes('auth') && window.WeixinJSBridge) {
      window.WeixinJSBridge.call('closeWindow');
    }
  }

  isOverdue = () => {
    return moment().isAfter(moment(this.state.detail.restrictionEndTime))
  }

  render() {
    document.title = intl.get('RESTRICTION_PARTNER_TITLE')
    const { detail } = this.state
    return (
      <>
        <PageContainer>
          <TitleDescription>
            <span>您查询的</span>
            <BlackText>{detail.name}</BlackText>
            {
              detail.isCanCooperate &&
              <GreenText>暂未纳入限制合作方名单</GreenText>
            }
            {
              !detail.isCanCooperate && detail.type === RESTRICTIVE_PARTNER_TYPE.ENUM.PERSONAL.value &&
              <RedText>疑似被限制合作方，具体请咨询合规办公室，联系方式 compliance.office@cn.mcd.com</RedText>
            }
            {
              !detail.isCanCooperate && detail.type === RESTRICTIVE_PARTNER_TYPE.ENUM.COMPANY.value && detail.restrictionType === RESTRICTIVE_TYPE.ENUM.TYPE_A.value &&
              <RedText>为永久禁止合作方</RedText>
            }
            {
              !detail.isCanCooperate && detail.type === RESTRICTIVE_PARTNER_TYPE.ENUM.COMPANY.value && (detail.restrictionType === RESTRICTIVE_TYPE.ENUM.TYPE_B.value || detail.restrictionType === RESTRICTIVE_TYPE.ENUM.TYPE_C.value) &&
              <RedText>需要进行专项合规评估后才能确定是否可合作，具体请咨询合规办公室，联系方式 compliance.office@cn.mcd.com</RedText>
            }
          </TitleDescription>
          {
            detail.isCanCooperate &&
            <>
              <br/>
              <HighlightTitleDescription>请注意：此查询仅支持公司名称或个人姓名完整输入查询，如输入信息不完整或有误，可能导致查询结果不准确。</HighlightTitleDescription>
            </>
          }
        </PageContainer>
        {
          !detail.isCanCooperate &&
          <PageContainer>
            <TitleDescription>如有异议请下载“异议申请表”，填写完整后请连同支持性文件一并邮件发送至合规办公室邮箱compliance.office@cn.mcd.com</TitleDescription>
            <DownloadButton onClick={() => {
              this.downloadFile(Config.downloadApplicationExcel)
            }}>
              <DownloadExcelIcon />
              <div>下载异议申请表</div>
            </DownloadButton>
            <DownloadButton onClick={() => {
              this.downloadFile(Config.downloadApplicationExcelTemplate)
            }}>
              <DownloadTemplateIcon />
              <div>查看异议申请表模板样例</div>
            </DownloadButton>
          </PageContainer >
        }
      </>
    )
  }
}

export default withRouter(Index)

const PageContainer = styled.div`
  margin: 16px;
  padding: 28px 32px 48px;
  background: white;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0px 2px 4px 0px rgba(223,230,234,1);
`
const TitleDescription = styled.div`
  color: #7B7B7B;
`
const HighlightTitleDescription = styled.div`
  color: #f56c6c;
`
const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  padding-left: 14px;
  gap: 4px;
  margin-top: 20px;
  background: RGB(24, 144, 255, 0.1);
  border-radius: 30px;
  height: 32px;
  width: 204px;
  cursor: pointer;
  font-weight: 600;
  line-height: 14px;
`

const GreenText = styled.span`
  color: #499E14;
  padding: 0 5px;
  font-weight: bold;
`

const RedText = styled.span`
  color: #C43030;
  padding: 0 5px;
  font-weight: bold;
`

const BlackText = styled.span`
  color: #333333;
  padding: 0 5px;
  font-weight: bold;
`
